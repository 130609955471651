import React, { createContext, useCallback, useContext, useState } from "react";
// import useAuth from "@shared/store/auth/hook";
// import { useGlobal } from "@shared/contexts/global/GlobalContext";

import IProject from "../interfaces/IProject";
import ISteering from "../interfaces/ISteering";
import IProjectReport from "../interfaces/IProjectReport";
import GetProjectService from "@modules/projects/services/Project/GetProjectService";
import { useGlobal } from "@shared/contexts/global/GlobalContext";
import useAuth from "@shared/store/auth/hook";
import { IInputErrorsFormat } from "@shared/utils/beforeSubmitForm";

export type IProjectsContextData = {
  clearProjectCreate: () => void;
  clearSteeringCreate: () => void;
  clearReportCreate: () => void;
  setProjectCurrent: React.Dispatch<React.SetStateAction<IProject>>;
  setSteeringCurrent: React.Dispatch<React.SetStateAction<ISteering>>;
  setReportCurrent: React.Dispatch<React.SetStateAction<IProjectReport>>;
  setErros: React.Dispatch<React.SetStateAction<IInputErrorsFormat[]>>
  getSteering: (steeringId) => Promise<boolean>;
  getProject: (projectId) => Promise<boolean | void>;
  getReport: (projectId) => Promise<boolean>;
  steeringCurrent?: ISteering;
  projectCurrent?: IProject;
  reportCurrent?: IProjectReport;
  errors: IInputErrorsFormat[]
};

const ProjectsContext = createContext<IProjectsContextData>(
  {} as IProjectsContextData
);

const ProjectsProvider: React.FC = ({ children }) => {
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const [projectCurrent, setProjectCurrent] = useState<IProject>({} as IProject);
  const [steeringCurrent, setSteeringCurrent] = useState<ISteering>({} as ISteering);
  const [reportCurrent, setReportCurrent] = useState<IProjectReport>({} as IProjectReport);
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);

  const clearProjectCreate = () => {
    setProjectCurrent({} as IProject);
  }

  const clearSteeringCreate = () => {
    setSteeringCurrent({} as ISteering);
  }

  const clearReportCreate = () => {
    setReportCurrent({} as IProjectReport);
  }

  const getProject = useCallback(async (projectId: string): Promise<boolean | void> => {
    if (!signedUser) return false;
    if(projectCurrent && Number(projectCurrent.id) === Number(projectId)) return
    setIsLoading(true);
    const res = await new GetProjectService(signedUser.token).execute(projectId);
    setIsLoading(false);
    if (!res) return false;
    setProjectCurrent(res);
    return true
  }, [projectCurrent, setIsLoading, signedUser]);


  const getSteering = useCallback(async (steeringId: string): Promise<boolean> => {
    setSteeringCurrent({
      id: '1',
      date: new Date(),
      status: 'implantation',
      type: 'implantation',
      channel: 'channel',
      link: 'link',
      action_plan: 'action_plan',
      ata: 'ata',
      next_date: new Date(),
      participating_alicerce: 'participating_alicerce',
      participating_partner: 'participating_partner'
    })
    return true
  }, []);


  const getReport = useCallback(async (reportId: string): Promise<boolean> => {
    setReportCurrent({
      id: '1',
      date: new Date(),
      type: 'implantation',
      description: 'implantation',
      img_attachments: [],
      others_attachments: []
    })
    return true
  }, []);


  return (
    <ProjectsContext.Provider
      value={{
        projectCurrent,
        steeringCurrent,
        reportCurrent,
        errors,
        getProject,
        getSteering,
        getReport,
        clearSteeringCreate,
        clearProjectCreate,
        clearReportCreate,
        setProjectCurrent,
        setSteeringCurrent,
        setReportCurrent,
        setErros
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};

function useProjects() {
  const context = useContext(ProjectsContext);

  if (!context) {
    throw new Error("useProjects must be used within a ProjectsProvider");
  }

  return context;
}

export { ProjectsProvider, useProjects };


