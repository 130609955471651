import React, { useCallback } from 'react';

import ISteering from '@modules/projects/interfaces/ISteering';

import { ISelectOption } from 'react-alicerce-components';
import { useProjects } from '@modules/projects/context/ProjectsContext';

export const useSteeringForm = () => {
    const { setSteeringCurrent } = useProjects();

    const handleChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
            const inputValue = event.target.value;
            const inputName = event.target.name;
            setSteeringCurrent((oldState: ISteering) => ({ ...oldState, [inputName]: inputValue }));
        },
        [setSteeringCurrent]
    );

    const handleSelectChange = useCallback(
        (option: ISelectOption | null) => {
            if (!option) return
            setSteeringCurrent((oldState: ISteering) => ({ ...oldState, [String(option?.selectname)]: option.value }));
        },
        [setSteeringCurrent]
    );

    const handleRemoveField = (key: string) => {
        setSteeringCurrent((oldState: any) => {
            delete oldState[key]
            return oldState
        });
    }

    const handleParticipatingPartner = (p: any) => { }
    const handleParticipatingAlicerce = (a: any) => { }

    return {
        handleChangeInput,
        handleSelectChange,
        handleRemoveField,
        handleParticipatingAlicerce,
        handleParticipatingPartner
    }
}
