import React, { Fragment, useCallback } from 'react';
import { Container, StyledButtonContainer, ContainerMediaPreview } from './styles';
import Icon from 'react-eva-icons';
import MediaAttach from '@shared/components/MediaAttach';
import { IAttachment } from '../../interfaces/IAttachment';
import { Button } from 'react-alicerce-components';

interface IButtonAddFilesProps {
  filesToSave?: (File | IAttachment)[];
  callbackFilesToSave: (files: (File | IAttachment)[]) => void;
  callbackFilesToRemove?: (indexInArray: number) => void;
  iconName: string;
  text: string;
  colorBackgroundIcon?: string;
  colorBackgroundText?: string;
  downloadSingleFileCallback?: (file: File | IAttachment) => void;
}

const ButtonAddFiles: React.FC<IButtonAddFilesProps> = ({
  filesToSave = [],
  callbackFilesToSave,
  iconName,
  text,
  colorBackgroundIcon,
  colorBackgroundText,
  callbackFilesToRemove,
  downloadSingleFileCallback,
}) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const filesToSaveUrl = filesToSave as IAttachment[];

  const proxyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList | null = event.target.files;

      if (files && files.length) {
        const fileNames: File[] = [];

        for (let i = 0; i < files.length; i++) {
          const file = files.item(i);
          file && fileNames.push(new File([file], file.name, { type: file.type, lastModified: file.lastModified }));
        }

        callbackFilesToSave([...fileNames, ...(filesToSave || [])]);
        if (hiddenFileInput.current) hiddenFileInput.current.value = '';
      }
    },
    [callbackFilesToSave, filesToSave]
  );

  return (
    <>
      <Container>
        {callbackFilesToRemove && (
          <Fragment>
            <input type="file" multiple ref={hiddenFileInput} onChange={(e) => proxyChange(e)} />
            <StyledButtonContainer onClick={() => hiddenFileInput.current?.click()}>
              <Icon name={iconName} />
            </StyledButtonContainer>
            <div className="text-add-button">{text}</div>
          </Fragment>
        )}
      </Container>
      <ContainerMediaPreview>
        {filesToSaveUrl?.map((file, index) => (
          <Container style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }} key={index}>
            <MediaAttach
              media={file}
              handleOnClick={downloadSingleFileCallback ? () => downloadSingleFileCallback(file) : undefined}
              handleOnDelete={callbackFilesToRemove ? () => callbackFilesToRemove(index) : undefined}
              colorBackgroundIcon={colorBackgroundIcon}
              colorBackgroundText={colorBackgroundText}
            />
            {!callbackFilesToRemove && (
              <Button
                start={<Icon name="search" />}
                fill="#B85EC4"
                rounded
                size={'small'}
                status={'success'}
                onClick={() => window.open(file.url, '_blank')}
              >
                Ver Aquivo
              </Button>
            )}
          </Container>
        ))}
      </ContainerMediaPreview>
    </>
  );
};

export { ButtonAddFiles };
