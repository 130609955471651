import { ISelectOption } from 'react-alicerce-components';

export const TYPE_CORE = { name: 'Core', value: 'core' };
export const TYPE_ENGLISH = { name: 'Inglês', value: 'english' };
export const TYPE_PREP_COURSE = { name: 'Curso Preparatório', value: 'prep_course' };
export const TYPE_CLUB = { name: 'Clube', value: 'club' };
export const TYPE_TRIAL = { name: 'Turma Gratuita', value: 'trial' };

export type IClassValidTypes = 'core' | 'english' | 'prep_course' | 'club' | 'adaptation' | 'trial';

export const CLASS_TYPE_CONSTRAINTS = [TYPE_CORE, TYPE_ENGLISH, TYPE_PREP_COURSE, TYPE_CLUB, TYPE_TRIAL];

export const defaultType = (classType?: string | null): ISelectOption[] | undefined => {
  return [
    { name: TYPE_CORE.name, value: TYPE_CORE.value, selected: classType === TYPE_CORE.value },
    { name: TYPE_ENGLISH.name, value: TYPE_ENGLISH.value, selected: classType === TYPE_ENGLISH.value },
    { name: TYPE_PREP_COURSE.name, value: TYPE_PREP_COURSE.value, selected: classType === TYPE_PREP_COURSE.value },
    { name: TYPE_CLUB.name, value: TYPE_CLUB.value, selected: classType === TYPE_CLUB.value },
    { name: TYPE_TRIAL.name, value: TYPE_TRIAL.value, selected: classType === TYPE_TRIAL.value },
  ];
};
