import React, { useCallback } from 'react';
import { useClass } from '@modules/classes/context/ClassContext';
import StudentList from '@modules/students/components/StudentsList';
import { IStudent } from '@modules/students/interfaces/IStudent';
import IClass from '@modules/classes/interfaces/IClass';
import { IEnrollment } from '../../../subscriptions/interfaces/IEnrollment';

const ClassStudentsRelation: React.FC = () => {
  const { setClassCurrent, classCurrent } = useClass();

  const setSelectedItens = useCallback(
    (students: IStudent[]) => {
      setClassCurrent((oldState: IClass) => {
        const studentsToSave = students.map((student) => {
          return { student };
        }) as IEnrollment[];

        oldState.enrollments = studentsToSave;
        return { ...oldState };
      });
    },
    [setClassCurrent]
  );

  return (
    <StudentList
      headerTitle="Diretório de Alunos"
      btnText="Adicionar Aluno"
      selectedItens={classCurrent.enrollments ? classCurrent.enrollments.flatMap((enrollment) => (enrollment.student ? enrollment.student : [])) : []}
      setSelectedItens={setSelectedItens}
      canSelectStudent="many"
      handleOnClick={setSelectedItens}
    />
  );
};

export { ClassStudentsRelation };
