import * as Yup from 'yup';

export const SteeringSchema = Yup.object().shape({
  date: Yup.date().required({ name: 'date', message: 'O campo é obrigatório' }),
  type: Yup.string()
    .oneOf(['implantation', 'execution', 'results', 'renovation', 'kickoff'], { name: 'type', message: `Deve ser do tipo` })
    .required({ name: 'type', message: 'O campo é obrigatório' }),
  status: Yup.string()
    .oneOf(['scheduled', 'done', 'not_done'], { name: 'status', message: "Deve ser do tipo 'scheduled', 'done', 'not_done'" })
    .required({ name: 'status', message: 'O campo é obrigatório' }),
  channel: Yup.string()
    .oneOf(['zoom', 'meet', 'teams', 'in_person'], { name: 'channel', message: "Deve ser do tipo 'zoom', 'meet', 'teams', 'in_person'" })
    .required({ name: 'channel', message: 'O campo é obrigatório' }),
  link: Yup.string()
    .url('Digite uma url valida')
    .required({ name: 'link', message: 'O campo é obrigatório' }),
  next_date: Yup.date().required({ name: 'next_date', message: 'O campo é obrigatório' }),
  participating_partner: Yup.string().required({ name: 'participating_partner', message: 'O campo é obrigatório' }),
  participating_alicerce: Yup.string().required({ name: 'participating_alicerce', message: 'O campo é obrigatório' }),
  ata: Yup.string().required({ name: 'ata', message: 'O campo é obrigatório' }),
  action_plan: Yup.string().required({ name: 'action_plan', message: 'O campo é obrigatório' })
});