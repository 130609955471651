import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { formatDate, Label } from 'react-alicerce-components';
import Icon from 'react-eva-icons';

import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import QuestionResume from '@modules/applications/components/QuestionResume';
import GetApplicationByIdService from '@modules/applications/services/GetApplicationByIdService';
import {
  formatApplication,
  formatApplicationDissertative,
  formatApplicationHv,
  IFormattedApplication,
} from '@modules/applications/utils/formatApplication';

import { Container, Grade, Header } from './styles';

interface IApplicationResumeContainer {
  lastApplicationId?: string;
  showHeaderLearningTarget?: boolean;
}

const ApplicationResumeContainer: React.FC<IApplicationResumeContainer> = (props) => {
  const { lastApplicationId, showHeaderLearningTarget = false } = props;
  const { applicationId } = useParams();

  const currentApplication = applicationId || lastApplicationId;

  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const [application, setApplication] = useState<IFormattedApplication>();

  const getApplication = useCallback(async () => {
    if (!signedUser || !currentApplication) return;

    setIsLoading(true);
    const res = await new GetApplicationByIdService(signedUser.token).execute(currentApplication as string);
    setIsLoading(false);

    if (!res) return;
    if (!!res.state?.mapaData || !!res.state?.miniMapaData) return setApplication(formatApplication(res));
    if (!!res.state?.dissertativeData) return setApplication(formatApplicationDissertative(res));
    setApplication(formatApplicationHv(res));
  }, [currentApplication, setIsLoading, signedUser]);

  const aplicationStatusAndDate = useMemo(() => {
    if (!application) return;
    if (!application?.finalized) {
      return (
        <span className="date">
          <b>Aplicação em Andamento</b>
        </span>
      );
    } else if (application?.finalized) {
      return (
        <span className="date">
          Finalizado em: <b>{formatDate(new Date(application?.finalized), 'numeric')}</b>
        </span>
      );
    }
  }, [application]);

  useEffect(() => {
    getApplication();
  }, [getApplication]);

  return (
    <Container>
      {showHeaderLearningTarget && (
        <Header>
          <span className="title">{application?.type}</span>
          {aplicationStatusAndDate}
        </Header>
      )}

      <Label marginTop={showHeaderLearningTarget ? '2rem' : 'none'}>Total de Tempo de Prova:</Label>
      {application?.totalTestTime && (
        <Grade>
          <span>{application?.totalTestTime}</span>
          <Icon name="clock" fill="#8F9BB3" />
        </Grade>
      )}

      {application?.questions?.map((question, i) => (
        <QuestionResume key={i} question={question} />
      ))}
    </Container>
  );
};

export default ApplicationResumeContainer;
