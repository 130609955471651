export const STEERING_TYPE_IMPLANTATION = { name: 'Implementação', value: 'implantation' };
export const STEERING_TYPE_EXECUTION = { name: 'Execução', value: 'execution' };
export const STEERING_TYPE_RESULTS = { name: 'Resultados', value: 'results' };
export const STEERING_TYPE_RENOVATION = { name: 'Renovação', value: 'renovation' };
export const STEERING_TYPE_KICKOFF = { name: 'Kickoff', value: 'kickoff' };


export const STEERING_TYPE_CONSTRAINTS = [STEERING_TYPE_IMPLANTATION, STEERING_TYPE_EXECUTION, STEERING_TYPE_RESULTS, STEERING_TYPE_RENOVATION, STEERING_TYPE_KICKOFF];

export const TYPE_STEERING_VALUES = STEERING_TYPE_CONSTRAINTS.map((c) => c.value);

export const defaultSteeringType = (steeringType?: string | null) => STEERING_TYPE_CONSTRAINTS.map(i => ({ ...i, selected: steeringType === i.value }))
