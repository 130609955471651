import React from "react";
import { useState } from "react";
import { DotStep, DotStepContainer, FormContainer } from "./styles";

export type MultiStepProps = {
    finalData: any
    steps: MultiStepItemsProps
    setFinalData: (item: any) => void
    handleSubmitCallback: () => void;
}

export type MultiStepItemsProps = {
    id: string,
    component: any,
    componentProps?: any
}[]

export type MultiStepComponentProps = {
    onNext: () => void
    onBack: () => void
    setValueCallback?: (item: any) => void
    handleSubmit: () => void;
    finalData: any
    steps: MultiStepItemsProps
    currentStepNumber: number
}

export const MultiStep = ({ steps, setFinalData, finalData, handleSubmitCallback }: MultiStepProps) => {
    const [currentStepNumber, setCurrentStepNumber] = useState<number>(0);

    const handleNext = () => {
        setCurrentStepNumber((prevStep) => prevStep + 1);
    };

    const handleBack = () => {
        setCurrentStepNumber((prevStep) => prevStep - 1);
    };

    const handleSetValue = (item: any) => {
        setFinalData && setFinalData(item)
    }

    const handleSubmit = () => {
        handleSubmitCallback && handleSubmitCallback()
    }
    const currentStep = steps[currentStepNumber]
    return (
        <FormContainer>
            <DotStepContainer>
                {steps?.map((dot, index) => <DotStep key={`${dot?.id}${index}`} active={Number(currentStepNumber) === Number(index)} />)}
            </DotStepContainer>
            {React.createElement(currentStep.component, {
                onNext: handleNext,
                onBack: handleBack,
                setValueCallback: handleSetValue,
                handleSubmit: handleSubmit,
                finalData: finalData,
                steps: steps,
                currentStepNumber: currentStepNumber,
                ...currentStep.componentProps
            })}
        </FormContainer>
    );
};