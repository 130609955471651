import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, H6, useToast } from 'react-alicerce-components';
import { useNavigate, useParams } from 'react-router-dom';
import ConfirmModal from '@shared/components/ConfirmModal';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import ClassPlannerForm from '../../components/ClassPlannerForm';
import Icon from 'react-eva-icons';
import { useScheduleGrid } from '../../context/ScheduleGridContext';
import { formatDailyClass } from '../../utils/formatDailyClass';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { dailyClassSchema } from '../../utils/dailyClassSchema';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useAuth from '@shared/store/auth/hook';
import CreateDailyClassService from '../../services/CreateDailyClassService';
import { IFormattedDailyClass } from '../../interfaces/IFormattedDailyClass';
import GetClassService from '@modules/classes/services/GetClassService';
import Divider from '@shared/components/Divider';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import UserCard from '@modules/users/components/UserCard';
import { IDailyClass } from '@modules/attendances/interfaces/IDailyClass';

const ClassPlannerCreate: React.FC = () => {
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const { dailyClass, setDailyClass } = useScheduleGrid();
  const [formattedDailyClass, setFormattedDailyClass] = useState<IFormattedDailyClass>();
  const { classId } = useParams<{ classId: string }>();
  const [errors, setErrors] = useState<IInputErrorsFormat[]>([]);
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();

  console.log(dailyClass, 'dailyClass<<<<')

  const canSaveDailyClass = useCallback(async () => {
    const formatedBody = formatDailyClass(dailyClass);
    let formErros = await beforeSubmitForm({ ...formatedBody, class_id: classId }, dailyClassSchema);
    setErrors(formErros);
    if (formErros.length !== 0) return;
    const auxFormattedBody = formatedBody as IFormattedDailyClass;
    setShowConfirmModal(true);
    setFormattedDailyClass(auxFormattedBody);
  }, [classId, dailyClass]);

  const handleClickOnSave = useCallback(async () => {
    if (!classId) return console.log(`classId is undefined`);
    setShowConfirmModal(false);
    if (!formattedDailyClass) return console.log('formattedDailyClass id undefined');
    setIsLoading(true);
    const res = await new CreateDailyClassService(signedUser?.token).execute({ ...formattedDailyClass, class_id: classId });
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Aula',
        description: 'Erro ao criar o Aula.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Aula',
      description: 'Aula criada com sucesso.',
    });

    setDailyClass(undefined); //same as reset dailyClass
    navigate(`/planejamento-aula/aulas/turma/${classId}`);
  }, [addToast, classId, formattedDailyClass, navigate, setDailyClass, setIsLoading, signedUser]);

  const getClass = useCallback(async () => {
    if (!classId) return navigate('/minhas-turmas');
    setIsLoading(true);
    const classFound = await new GetClassService(signedUser?.token).execute(classId);
    setIsLoading(false);
    if (!classFound) return navigate('/minhas-turmas');

    setDailyClass((oldState: IDailyClass) => {
      return { ...oldState, plan: { teachers: classFound.teachers } };
    });
  }, [classId, navigate, setDailyClass, setIsLoading, signedUser]);

  useEffect(() => {
    if (dailyClass?.plan?.teachers) return;
    getClass();
  }, [dailyClass, getClass]);

  const removeUserCard = useCallback(
    (index: number) => {
      if (!dailyClass || !dailyClass.plan?.teachers) return;
      const newUsers = dailyClass.plan?.teachers.filter((_, id) => id !== index);
      setDailyClass((oldState: IDailyClass) => {
        return { ...oldState, plan: { ...oldState.plan, teachers: newUsers } };
      });
    },
    [dailyClass, setDailyClass]
  );

  const handleAddUsersButton = useCallback(() => {
    if (!dailyClass) return;
    navigate('/planejamento-aula/criar/selecionar/usuarios');
  }, [dailyClass, navigate]);

  return (
    <Fragment>
      <HeaderDirectory
        title="Criar Aula"
        pathToReturn={`/planejamento-aula/aulas/turma/${classId}`}
        backgroundHeader="linear-gradient(284.92deg, #B390DB 0%, #70C3FC 100%)"
      />
      <DirectoryContainer footer={true}>
        <ClassPlannerForm errors={errors} pathEnvolvedPlace={`/planejamento-aula/turma/${classId}/selecionar/lugar`} />

        <Divider />

        <H6 fontWeight={800} color="#B85EC4">
          PARTICIPANTES
        </H6>

        <AccordionInfoItem
          colorHeader="#DAE3F6"
          title={`Líderes (${dailyClass && dailyClass.plan?.teachers ? dailyClass.plan?.teachers.length : 0})`}
        >
          <ButtonAddCard text="Inserir Usuários" handleOnClick={handleAddUsersButton} />
          {dailyClass?.plan?.teachers?.map((lmsUser, index) => (
            <UserCard
              key={lmsUser.id}
              canSelect={false}
              user={lmsUser}
              canDeleteCard={{ indexId: index, removeCallback: () => removeUserCard(index) }}
            />
          ))}
        </AccordionInfoItem>

        <PageFooter>
          <Button
            transform="none"
            outline
            shaded
            onClick={() => navigate(`/planejamento-aula/aulas/turma/${classId}`)}
            className="button-cancel-activity"
          >
            Cancelar
          </Button>
          <Button transform="none" shaded onClick={canSaveDailyClass} className="mochileiro-color">
            Salvar
          </Button>
        </PageFooter>

        <ConfirmModal
          start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
          showModal={showConfirmModal}
          closeCallback={() => setShowConfirmModal(false)}
          title="Deseja Criar Aula?"
          textConfirmButton="Confirmar"
          textCloseButton="Cancelar"
          confirmCallBack={handleClickOnSave}
        />
      </DirectoryContainer>
    </Fragment>
  );
};

export default ClassPlannerCreate;
