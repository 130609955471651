import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    display: flex;

    .custom-card {
        width: 100%;
        margin: 0;

        > div {
            margin-bottom: 0;
        }
    }

   
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #F7652B;

        h6 {
            color: #fff;
            font-size: 14px;
            font-weight: 800;

            > div {
                border-radius: 6px;
            }
        }
    }

    .card-body {
        padding: 20px;
        justify-content: start;
        background-color: #E9C4B6;
        border-radius: 0;

        p {
            font-size: 12px;
            font-weight: 600;
            color: #222B45;

            span {
                font-size: 16px;
                font-weight: 800;
            }
        }
    }

    .card-footer {
        display: flex;
        align-items: center;
        border-top: none;
        min-height: 40px;
        border-bottom-left-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        background-color: #F7652B;
    }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;

    > div {
        display: flex;

        b {
            font-size: 12px;
            color: #fff;
            align-self: center;
        }
        
        .tag {
            border-radius: 5px;
            background-color: #C5CEE0;
            color: #222B45;
            border-color: transparent;
        }

        & + div:before {
            content: '';
            width: 2.5px;
            border-radius: 3px;
            padding: 1px;
            background #fff;
            margin: 0 1rem;
        }
    }
`;
