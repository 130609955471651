import React, { Fragment, useCallback, useEffect, useMemo } from 'react';

import { H6, Input, Select, Textarea } from 'react-alicerce-components';
import CustomForm from '@shared/components/CustomForm';

import { useStudent } from '@modules/students/context/StudentContext';

import TagsRender from '@modules/tags/components/TagsRender';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import Divider from '@shared/components/Divider';
import { calcAge } from '@shared/utils/calculateAge';
import { WrapperStudentForm, WrapperInput } from './styles';
import {
  STATUS_ACTIVE,
  STATUS_ADAPTATION,
  STATUS_COMPLETER,
  STATUS_EXPERIMENTATION,
  STATUS_NOT_ENROLLED_AFTER_ADAPTATION,
  STATUS_NOT_ENROLLED_AFTER_EXPERIMENTATION,
  STATUS_PAUSED,
  STATUS_QUITTER,
  defaultStatus,
} from '@modules/students/utils/validData/validStatus';
import { defaultRelationships } from '@modules/students/utils/validData/validRelationships';
import InputAge from '@shared/components/InputAge';
import { defaultAgeGroup } from '@shared/utils/validData/validAgeGroup';
import { defaultSchoolGrade } from '@modules/students/utils/validData/validSchoolgrade';
import { IStudent } from '@modules/students/interfaces/IStudent';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import returnStringFunction from '@shared/utils/returnStringFunction';
import DashedBox from '@shared/components/DashedBox';
import { SubscriptionCard } from '@modules/subscriptions/components/SubscriptionCard';
import { ISubscription } from '@modules/subscriptions/interfaces/ISubscription';
import { defaultGender } from '@shared/utils/validData/validGender';
import { errorMessageBuilder, hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import ParentRender from '@shared/components/ParentRender';
import GroupCard from '../../../groups/components/GroupCard';
import { useNavigate } from 'react-router-dom';
import ClassCard from '../../../classes/components/ClassCard';
import { defaultShoolType } from '../../utils/validData/validSchoolType';
import { defaultSchoolTransport } from '../../utils/validData/validSchoolTransport';
import { defaultLiterate } from '@shared/utils/validData/validLiterate';
import { datePickerFormat } from '@shared/utils/datePickerFormat';
import { defaultCancellationReason, defaultConclusionReason } from '@modules/students/utils/validData/validCancellationReasons';
import { defaultCancellationDetails } from '../../utils/validData/validCancellationDetails';
import { LifeStageStudentCard } from '../LifeStageStudentCard';

interface IStudentEditProps {
  form_action: 'creating' | 'editing' | 'watching';
  errors: IInputErrorsFormat[];
  refResponsiblePicking: React.RefObject<any>;
  refPersonalAcademicData: React.RefObject<any>;
  refAlicerceMainData: React.RefObject<any>;
  removedSubscription?: (removedSubcription: ISubscription) => void;
  pathOnboardingGroup?: string;
  pathEnvolvedClasses?: string;
}

const StudentForm: React.FC<IStudentEditProps> = ({
  removedSubscription,
  errors,
  refAlicerceMainData,
  refPersonalAcademicData,
  refResponsiblePicking,
  form_action,
  pathOnboardingGroup,
  pathEnvolvedClasses,
}) => {
  const { studentCurrent, setStudentCurrent } = useStudent();
  const navigate = useNavigate();

  const RenderLifeStageStudentCard = () => {
    if (studentCurrent.stage_of_life_definitions && studentCurrent.stage_of_life_definitions.length > 0) {
      return (<>
        {studentCurrent.stage_of_life_definitions.map((lifeStage, index) => (
          <LifeStageStudentCard
            key={index}
            lifeStage={lifeStage}
            grades={studentCurrent.grades}
          />
        ))}
      </>
      );
    }
    return <p style={{ marginTop: '10px' }}>Nenhum Estágio de Vida para mostrar...</p>;
  };

  const removeItemFromArray = useCallback(
    (indexToRemove: number, objectKey: string) => {
      setStudentCurrent((oldState: IStudent) => {
        if (objectKey === 'enrollments') {
          const hasSubscription = oldState.enrollments ? oldState.enrollments[indexToRemove].subscription : undefined;
          if (hasSubscription) oldState.subscriptions?.push(hasSubscription);
        }

        const newArray = removeItemFromArrayByIndex(indexToRemove, studentCurrent[objectKey] || []);
        return { ...oldState, [objectKey]: newArray };
      });
    },
    [setStudentCurrent, studentCurrent]
  );

  const handleSelectChange = useCallback(
    (option, selectName) => {
      if (selectName === 'cancellation_reason') {
        setStudentCurrent((oldState: IStudent) => {
          return { ...oldState, [selectName]: option.value, cancellation_type_detail: null };
        });
      }
      setStudentCurrent((oldState: IStudent) => {
        return { ...oldState, [selectName]: option.value };
      });
    },
    [setStudentCurrent]
  );

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const inputName = event.target.name;
      setStudentCurrent((oldState: IStudent) => {
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setStudentCurrent]
  );

  const handleChangeInputTextArea = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setStudentCurrent((oldState: any) => ({ ...oldState, [inputName]: inputValue }));
    },
    [setStudentCurrent]
  );

  const handleChangeInputObject = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setStudentCurrent((oldState: IStudent) => {
        return { ...oldState, [key]: { ...oldState[key], [inputName]: inputValue } };
      });
    },
    [setStudentCurrent]
  );

  const handleChangeInputArrayObject = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, key: string, index: number) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setStudentCurrent((oldState: IStudent) => {
        let aux = oldState[key];
        aux[index][inputName] = inputValue;
        return { ...oldState, [key]: aux };
      });
    },
    [setStudentCurrent]
  );

  const addNewResponsiblePicking = useCallback(() => {
    refResponsiblePicking.current.updateHeight();
    setStudentCurrent((oldState: IStudent) => {
      const newResponsible = { name: '', cpf: '' };
      if (!oldState['responsibles_for_picking']) return { ...oldState, responsibles_for_picking: [newResponsible] };
      return { ...oldState, responsibles_for_picking: [...oldState.responsibles_for_picking, newResponsible] };
    });
  }, [refResponsiblePicking, setStudentCurrent]);

  const titleResponsiblePicking = useMemo(() => {
    return `Permissão de Retirada (${studentCurrent.responsibles_for_picking ? studentCurrent.responsibles_for_picking.length : 0})`;
  }, [studentCurrent.responsibles_for_picking]);

  const removeSubscriptionCard = useCallback(
    (arrId: number, deletedSubscription: ISubscription) => {
      const filteredSubscriptions = studentCurrent.subscriptions?.filter((sub, id) => id !== arrId);

      setStudentCurrent((oldState: ISubscription) => {
        return { ...oldState, subscriptions: filteredSubscriptions };
      });

      removedSubscription && removedSubscription(deletedSubscription);
    },
    [removedSubscription, setStudentCurrent, studentCurrent.subscriptions]
  );

  const clearOnboardingGroup = () => {
    setStudentCurrent((oldState: IStudent) => {
      return { ...oldState, primary_affiliation: undefined, onboarding_group: undefined };
    });
  };

  const studentClasses = useMemo(() => {
    return studentCurrent.enrollments ? studentCurrent.enrollments.map((e) => e.class) : [];
  }, [studentCurrent.enrollments]);

  useEffect(() => {
    if (form_action === 'watching' || studentCurrent.status === undefined) return;
    if (
      studentCurrent.status === STATUS_ACTIVE.value ||
      studentCurrent.status === STATUS_ADAPTATION.value ||
      studentCurrent.status === STATUS_EXPERIMENTATION.value ||
      studentCurrent.status === STATUS_PAUSED.value
    ) {
      setStudentCurrent((oldState: IStudent) => ({ ...oldState, cancellation_details: null, cancellation_reason: null, canceled_at: null, cancellation_type_detail: null }));
    }
  }, [form_action, setStudentCurrent, studentCurrent.status]);

  const cancellationDetailsOptions = defaultCancellationDetails(studentCurrent.cancellation_reason, studentCurrent.cancellation_type_detail)
  return (
    <Fragment>
      <CustomForm customInputColorText="#35A0D6">
        <WrapperStudentForm>
          <H6 fontWeight={800} status="secondary">
            DADOS PESSOAIS
          </H6>

          <AccordionInfoItem
            title="Dados Principais"
            status="secondary"
            hasErrors={hasError(['name', 'gender', 'phone', 'date', 'address', 'city', 'uf'], errors)}
          >
            <Input
              onChange={(event) => handleChangeInput(event)}
              label="Nome do aluno:"
              name="name"
              defaultValue={studentCurrent.name}
              required
              useTooltipError={false}
              error={errorMessageBuilder('name', errors)}
              disabled={form_action === 'watching'}
            />

            <Input
              onChange={(event) => handleChangeInput(event)}
              label="Telefone:"
              name="phone"
              defaultValue={studentCurrent.phone || ''}
              mask="(##)#########"
              useTooltipError={false}
              error={errorMessageBuilder('phone', errors)}
              disabled={form_action === 'watching'}
            />

            <Input
              onChange={(event) => handleChangeInputObject(event, 'address_data')}
              label="Endereço:"
              name="address"
              required
              defaultValue={returnStringFunction(studentCurrent.address_data?.address)}
              useTooltipError={false}
              error={errorMessageBuilder('address', errors)}
              disabled={form_action === 'watching'}
            />

            <WrapperInput>
              <Input
                onChange={(event) => handleChangeInputObject(event, 'address_data')}
                label="Cidade:"
                name="city"
                required
                defaultValue={returnStringFunction(studentCurrent.address_data?.city)}
                useTooltipError={false}
                error={errorMessageBuilder('city', errors)}
                disabled={form_action === 'watching'}
              />

              <Input
                onChange={(event) => handleChangeInputObject(event, 'address_data')}
                label="UF:"
                name="uf"
                mask="##"
                required
                defaultValue={returnStringFunction(studentCurrent.address_data?.uf)}
                useTooltipError={false}
                error={errorMessageBuilder('uf', errors)}
                disabled={form_action === 'watching'}
              />
            </WrapperInput>

            <WrapperInput>
              <Input
                onChange={(event) => handleChangeInput(event)}
                label="Data de Nascimento:"
                name="birthdate"
                type="date"
                defaultValue={returnStringFunction(studentCurrent.birthdate)}
                required
                useTooltipError={false}
                error={errorMessageBuilder('birthdate', errors)}
                disabled={form_action === 'watching'}
              />
              <InputAge label="Idade:">{studentCurrent.birthdate ? calcAge(studentCurrent.birthdate) : ''}</InputAge>
            </WrapperInput>

            <Select
              label="Sexo:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'gender')}
              options={defaultGender(studentCurrent.gender)}
              required
              error={errorMessageBuilder('gender', errors)}
              disabled={form_action === 'watching'}
            />
          </AccordionInfoItem>

          <AccordionInfoItem
            title="Dados Acadêmicos"
            status="secondary"
            refSize={refPersonalAcademicData}
            hasErrors={hasError(['literate_status', 'school_grade', 'age_group'], errors)}
          >
            <Select
              customStyle={{ marginTop: '10px' }}
              label="Aluno(a) Alfabetizado(a) ?"
              iconName="arrow-down"
              placeholder="Selecione"
              handleOnClick={() => refPersonalAcademicData.current.updateHeight()}
              handleSelectChange={(option) => handleSelectChange(option, 'literate_status')}
              options={defaultLiterate(studentCurrent.literate_status)}
              required
              error={errorMessageBuilder('literate_status', errors)}
              disabled={form_action === 'watching'}
            />

            <Select
              label="Grupo Etário:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleOnClick={() => refPersonalAcademicData.current.updateHeight()}
              handleSelectChange={(option) => handleSelectChange(option, 'age_group')}
              options={defaultAgeGroup(studentCurrent.age_group)}
              required
              error={errorMessageBuilder('age_group', errors)}
              disabled={form_action === 'watching'}
            />

            <Select
              customStyle={{ marginTop: '10px' }}
              label="Série Escolar:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'school_grade')}
              handleOnClick={() => refPersonalAcademicData.current.updateHeight()}
              options={defaultSchoolGrade(studentCurrent.school_grade)}
              required
              error={errorMessageBuilder('school_grade', errors)}
              disabled={form_action === 'watching'}
            />

            <Input
              onChange={(event) => handleChangeInput(event)}
              label="Nome da Organização Escolar:"
              name="school_organization"
              placeholder={form_action === 'watching' ? undefined : 'Adcione Nome da Organização Escolar'}
              defaultValue={studentCurrent.school_organization}
              useTooltipError={false}
              error={errorMessageBuilder('school_organization', errors)}
              disabled={form_action === 'watching'}
              required
            />

            <Select
              customStyle={{ marginTop: '10px' }}
              label="Tipo da Escola:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'school_type')}
              options={defaultShoolType(studentCurrent.school_type)}
              required
              error={errorMessageBuilder('school_type', errors)}
              disabled={form_action === 'watching'}
            />
          </AccordionInfoItem>

          <Divider width="100%" />

          <H6 fontWeight={800} status="secondary">
            DADOS ALICERCE
          </H6>

          <AccordionInfoItem
            title="Dados Principais"
            status="secondary"
            refSize={refAlicerceMainData}
            hasErrors={hasError(
              [
                'relationship_type',
                'transport_method',
                'status',
                'alicerce_email',
                'primary_affiliation',
                'canceled_at',
                'cancellation_reason',
                'cancellation_details',
                'start_date',
              ],
              errors
            )}
          >
            <Select
              customStyle={{ marginTop: '10px' }}
              label="Status do Aluno:"
              iconName="arrow-down"
              status="secondary"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'status')}
              handleOnClick={() => refAlicerceMainData.current.updateHeight()}
              options={defaultStatus(studentCurrent.status)}
              required
              error={errorMessageBuilder('status', errors)}
              disabled={form_action === 'watching'}
            />

            <Input
              onChange={(event) => handleChangeInput(event)}
              label="Data de início:"
              name="start_date"
              type="date"
              defaultValue={returnStringFunction(studentCurrent.start_date)}
              useTooltipError={false}
              error={errorMessageBuilder('start_date', errors)}
              disabled={form_action === 'watching'}
            />



            <Select
              customStyle={{ marginTop: '1.5rem' }}
              label="Tipos de Relacionamento:"
              iconName="arrow-down"
              status="secondary"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'relationship_type')}
              handleOnClick={() => refAlicerceMainData.current.updateHeight()}
              options={defaultRelationships(studentCurrent.relationship_type)}
              required
              error={errorMessageBuilder('relationship_type', errors)}
              disabled={form_action === 'watching'}
            />

            <ParentRender
              handleOnClick={form_action === 'watching' || !pathOnboardingGroup ? undefined : () => navigate(pathOnboardingGroup)}
              label="Coletivo de Onboarding"
              placeholder={form_action === 'watching' ? undefined : 'Adicione Coletivo de Onboarding'}
              hasParent={!!studentCurrent.onboarding_group}
              customStyles={{ margin: '1.5rem 0' }}
              error={errorMessageBuilder('primary_affiliation', errors)}
              required
            >
              {studentCurrent.onboarding_group && (
                <GroupCard
                  canSelect={false}
                  group={studentCurrent.onboarding_group}
                  inputCard={true}
                  canDeleteCard={form_action === 'watching' ? undefined : { removeCallback: () => clearOnboardingGroup() }}
                  customStyle={{ margin: '1rem' }}
                />
              )}
            </ParentRender>

            <TagsRender
              label="Projetos:"
              status="tertiary"
              placeholder={form_action === 'watching' ? undefined : 'Adicione Tags de Projeto'}
              tags={studentCurrent.partner_organizations_tag || []}
              tagCallback={form_action === 'watching' ? undefined : (indexTag) => removeItemFromArray(indexTag, 'partner_organizations_tag')}
              canRemoveTag={form_action === 'watching' ? false : true}
              pathToRedirect={form_action === 'watching' ? undefined : '/diretorio/alunos/selecionar/tags/projeto'}
            />

            <TagsRender
              label="Tags Relacionadas:"
              placeholder={form_action === 'watching' ? undefined : 'Adicione Tags'}
              status="tertiary"
              tags={studentCurrent.student_tags || []}
              tagCallback={form_action === 'watching' ? undefined : (indexTag) => removeItemFromArray(indexTag, 'student_tags')}
              canRemoveTag={form_action === 'watching' ? false : true}
              pathToRedirect={form_action === 'watching' ? undefined : '/diretorio/alunos/selecionar/tags/sistema'}
            />

            <Select
              customStyle={{ marginTop: '1.5rem' }}
              label="Principal Meio de Transporte para Ir ao Polo:"
              iconName="arrow-down"
              placeholder="Selecione"
              handleSelectChange={(option) => handleSelectChange(option, 'transport_method')}
              options={defaultSchoolTransport(studentCurrent.transport_method)}
              required
              error={errorMessageBuilder('transport_method', errors)}
              disabled={form_action === 'watching'}
            />

            <Divider width="100%" />

            {(studentCurrent.status === STATUS_QUITTER.value ||
              studentCurrent.status === STATUS_NOT_ENROLLED_AFTER_ADAPTATION.value ||
              studentCurrent.status === STATUS_NOT_ENROLLED_AFTER_EXPERIMENTATION.value ||
              studentCurrent.status === STATUS_COMPLETER.value) && (
                <Fragment>
                  <Input
                    onChange={(event) => handleChangeInput(event)}
                    label="Data de Desistência/Conclusão:"
                    name="canceled_at"
                    type="date"
                    defaultValue={studentCurrent.canceled_at && datePickerFormat(studentCurrent.canceled_at, '-', '*').slice(0, 10)}
                    useTooltipError={false}
                    error={errorMessageBuilder('canceled_at', errors)}
                    disabled={form_action === 'watching'}
                    required
                  />

                  <Select
                    label="Motivo de Desistência/Conclusão:"
                    iconName="arrow-down"
                    status="secondary"
                    placeholder="Selecione"
                    handleSelectChange={(option) => handleSelectChange(option, 'cancellation_reason')}
                    handleOnClick={() => refAlicerceMainData.current.updateHeight()}
                    options={
                      studentCurrent.status === STATUS_COMPLETER.value ?
                        defaultConclusionReason(studentCurrent.cancellation_reason) :
                          defaultCancellationReason(studentCurrent.cancellation_reason)
                    }
                    required
                    error={errorMessageBuilder('cancellation_reason', errors)}
                    disabled={form_action === 'watching'}
                  />

                  {studentCurrent.status !== STATUS_COMPLETER.value &&
                    <Select
                      key={studentCurrent.cancellation_type_detail}
                      label="Detalhe de Desistência/Conclusão:"
                      iconName="arrow-down"
                      status="secondary"
                      placeholder="Selecione"
                      handleSelectChange={(option) => handleSelectChange(option, 'cancellation_type_detail')}
                      handleOnClick={() => refAlicerceMainData.current.updateHeight()}
                      options={cancellationDetailsOptions}
                      error={errorMessageBuilder('cancellation_type_detail', errors)}
                      disabled={form_action === 'watching' || cancellationDetailsOptions?.length === 0}
                    />
                  }

                  <Textarea
                    name="cancellation_details"
                    label="Observações de Desistência/Conclusão:"
                    placeholder="Adicione as Observações da Desistência/Conclusão"
                    value={studentCurrent.cancellation_details}
                    rows={4}
                    onChange={(event) => handleChangeInputTextArea(event)}
                    error={errorMessageBuilder('cancellation_details', errors)}
                    disabled={form_action === 'watching'}
                  />
                </Fragment>
              )}

          </AccordionInfoItem>

          <AccordionInfoItem title={`Turmas Relacionadas (${studentClasses ? studentClasses.length : 0})`} status="secondary">
            {pathEnvolvedClasses && <ButtonAddCard text="Selecionar Turma" handleOnClick={() => navigate(pathEnvolvedClasses)} />}

            {studentCurrent.enrollments &&
              studentCurrent.enrollments.map((c, index) => (
                <ClassCard
                  canDeleteCard={
                    form_action === 'watching' ? undefined : { indexId: index, removeCallback: () => removeItemFromArray(index, 'enrollments') }
                  }
                  canSelect={false}
                  classesSelected={studentClasses}
                  classInfo={c.class}
                  key={c.class.id}
                />
              ))}
          </AccordionInfoItem>

          <AccordionInfoItem title={'Estágio de Vida'} status="secondary">
            <RenderLifeStageStudentCard />
          </AccordionInfoItem>

          {form_action !== 'creating' && (
            <AccordionInfoItem
              status="secondary"
              title={`Assinaturas do Aluno (${studentCurrent.subscriptions ? studentCurrent.subscriptions.length : 0})`}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem', marginTop: '2rem' }}>
                {studentCurrent.subscriptions?.map((sub, index) => (
                  <SubscriptionCard
                    key={`${sub.id}-${studentCurrent.subscriptions}`}
                    subscription={sub}
                    canDeleteCard={
                      form_action === 'watching' ? undefined : { indexId: index, removeCallback: (id) => removeSubscriptionCard(id, sub) }
                    }
                  />
                ))}
              </div>
            </AccordionInfoItem>
          )}

          <Divider width="100%" />

          <H6 fontWeight={800} status="secondary">
            RESPONSÁVEL
          </H6>

          <AccordionInfoItem
            title="Dados do Responsável"
            status="secondary"
            hasErrors={hasError(['responsible_name', 'responsible_phone', 'responsible_email'], errors)}
          >
            <Input
              onChange={(event) => handleChangeInputObject(event, 'responsible')}
              label="Nome do Responsável:"
              name="name"
              defaultValue={returnStringFunction(studentCurrent.responsible?.name)}
              required
              useTooltipError={false}
              error={errorMessageBuilder('responsible_name', errors)}
              disabled={form_action === 'watching'}
            />
            <Input
              onChange={(event) => handleChangeInputObject(event, 'responsible')}
              label="Telefone do Responsável:"
              name="phone"
              mask="(##)#########"
              defaultValue={returnStringFunction(studentCurrent.responsible?.phone)}
              required
              useTooltipError={false}
              error={errorMessageBuilder('responsible_phone', errors)}
              disabled={form_action === 'watching'}
            />
            <Input
              onChange={(event) => handleChangeInputObject(event, 'responsible')}
              label="Email do Responsável:"
              name="email"
              defaultValue={returnStringFunction(studentCurrent.responsible?.email)}
              useTooltipError={false}
              error={errorMessageBuilder('responsible_email', errors)}
              disabled={form_action === 'watching'}
            />
          </AccordionInfoItem>

          <AccordionInfoItem
            title={titleResponsiblePicking}
            status="secondary"
            refSize={refResponsiblePicking}
            hasErrors={hasError(['responsible_picking_name'], errors)}
          >
            {form_action !== 'watching' && (
              <ButtonAddCard text="Adicionar Responsável para Retirada" handleOnClick={() => addNewResponsiblePicking()} />
            )}

            {studentCurrent.responsibles_for_picking?.map((responsiblePicking, index) => (
              <DashedBox
                key={`${index}-${studentCurrent.responsibles_for_picking?.length}`}
                handleDeleteCallback={form_action === 'watching' ? undefined : () => removeItemFromArray(index, 'responsibles_for_picking')}
              >
                <Input
                  onChange={(event) => handleChangeInputArrayObject(event, 'responsibles_for_picking', index)}
                  label="Nome do Responsável com Permissão para Tirar Aluno do Polo:"
                  placeholder="Adicione o nome do responsável"
                  name="name"
                  defaultValue={responsiblePicking.name || ''}
                  required
                  useTooltipError={false}
                  error={responsiblePicking.name.length === 0 ? errorMessageBuilder('responsible_picking_name', errors) : undefined}
                  disabled={form_action === 'watching'}
                />
                <Input
                  onChange={(event) => handleChangeInputArrayObject(event, 'responsibles_for_picking', index)}
                  label="CPF do Responsável com Permissão para Tirar Aluno do Polo:"
                  placeholder="Adicione o cpf do responsável"
                  name="cpf"
                  mask="###.###.###-##"
                  defaultValue={responsiblePicking.cpf || ''}
                  required
                  useTooltipError={false}
                  error={responsiblePicking.cpf?.length === 0 ? errorMessageBuilder('responsible_picking_cpf', errors) : undefined}
                  disabled={form_action === 'watching'}
                />
              </DashedBox>
            ))}
          </AccordionInfoItem>
        </WrapperStudentForm>
      </CustomForm>
    </Fragment>
  );
};

export default StudentForm;
