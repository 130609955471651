export const STEERING_STATUS_SCHEDULED = { name: 'Agendada', value: 'scheduled' };
export const STEERING_STATUS_DONE = { name: 'Realizada', value: 'done' };
export const STEERING_STATUS_NOT_DONE = { name: 'Não Realizada', value: 'not_done' };

export const STEERING_STATUS_CONSTRAINTS = [STEERING_STATUS_SCHEDULED, STEERING_STATUS_DONE, STEERING_STATUS_NOT_DONE];
export const STATUS_STEERING_VALUES = STEERING_STATUS_CONSTRAINTS.map((c) => c.value);

export const defaultSteeringStatus = (steeringStatus?: string | null) => STEERING_STATUS_CONSTRAINTS.map(i => ({ ...i, selected: steeringStatus === i.value }))

export const translateSteeringStatus = (status: string = '') => {
  let objMap = {}
  STEERING_STATUS_CONSTRAINTS.forEach(i => objMap = {...objMap, [i.value]: i.name })
  return objMap[status] || status
}