import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Divider, H6 } from 'react-alicerce-components';

import Layout from '@modules/projects/components/Layout';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import SteeringCard from '@modules/projects/components/SteeringCard';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import PageFooter from '@shared/components/PageFooter';

const ProjectSteerings: React.FC = () => {
  const navigate = useNavigate();
  const { projectCurrent } = useProjects();

  return (
    <Layout openTab={3} footer>
      <ProjectDefaultStylesContainer>
        <H6>
          PRÓXIMA STEERING:
        </H6>
        <SteeringCard />
        <H6 marginTop={5}>
          ÚLTIMA STEERING:
        </H6>
        <SteeringCard />

        <Divider width='100%' />

        <AccordionInfoItem title='Demais Steerings Realizadas'>
          {new Array(10).fill('').map((_o, i) => <SteeringCard key={i} />)}
        </AccordionInfoItem>
        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() => navigate(`/diretorio/projetos/${projectCurrent?.id}/steering/criar`)}
          >
            Criar Steering
          </Button>
        </PageFooter>
      </ProjectDefaultStylesContainer>
    </Layout>
  );
};

export default ProjectSteerings;
