import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, H6, Input, Select, Divider, formatDate } from 'react-alicerce-components';

import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import CustomForm from '@shared/components/CustomForm';
import { IInputErrorsFormat, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';

import { useSteeringForm } from '../useSteeringForm';
import { defaultSteeringType } from '@modules/projects/schemas/Steering/partials/SteeringType';
import { defaultSteeringStatus } from '@modules/projects/schemas/Steering/partials/SteeringStatus';
import { defaultSteeringChannel } from '@modules/projects/schemas/Steering/partials/SteeringChannel';

interface SteeringInfoFragmentProps {
    disabled?: boolean
    errors?: IInputErrorsFormat[];
    pathParticipatingPartner?: string;
    pathParticipatingAlicerce?: string
}

export const SteeringInfoFragment: React.FC<SteeringInfoFragmentProps> = ({
    errors,
    disabled,
    pathParticipatingPartner = '',
    pathParticipatingAlicerce = ''
}) => {

    const navigate = useNavigate();

    const { steeringCurrent } = useProjects();

    const {
        handleSelectChange,
        handleChangeInput
    } = useSteeringForm()

    return (
        <Grid row>
            <H6>INFORMAÇÕES BÁSICAS:</H6>
            <CustomForm status="primary">
                <Grid row>
                    <Grid sm={12} md={5} mt4>
                        <Input
                            disabled={disabled}
                            label="Data da Steering"
                            name="date"
                            type="date"
                            onChange={handleChangeInput}
                            defaultValue={formatDate(steeringCurrent?.date as Date, 'numeric')}
                            useTooltipError={false}
                            error={errorMessageBuilder('date', errors)}
                        />
                    </Grid>
                    <Grid sm={12} md={7} mt4>
                        <Select
                            disabled={disabled}
                            label="Tipo da Steering:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultSteeringType(steeringCurrent?.type)}
                            error={errorMessageBuilder('type', errors)}
                            name='type'
                        />
                    </Grid>
                </Grid>
                <Grid row>
                    <Grid sm={12} md={12} mt4>
                        <Select
                            disabled={disabled}
                            label="Status da Steering:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultSteeringStatus(steeringCurrent?.status)}
                            error={errorMessageBuilder('status', errors)}
                            name='status'
                        />
                    </Grid>
                </Grid>

                <Grid row>
                    <Grid sm={12} md={12} mt4>
                        <Select
                            disabled={disabled}
                            label="Canal da Reunião:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultSteeringChannel(steeringCurrent?.channel)}
                            error={errorMessageBuilder('channel', errors)}
                            name='channel'
                        />
                    </Grid>
                </Grid>

                <Grid row>
                    <Grid sm={12} md={12} mt4>
                        <Input
                            disabled={disabled}
                            name="link"
                            placeholder="Adicione Link do Canal da Reunião"
                            label="Link do Canal da Reunião:"
                            onChange={handleChangeInput}
                            defaultValue={steeringCurrent?.link}
                            useTooltipError={false}
                            required
                            error={errorMessageBuilder('link', errors)}
                        />
                    </Grid>
                </Grid>

                <Divider width='100%' />

                <Grid sm={12} md={5} mt4>
                    <Input
                        disabled={disabled}
                        label="Data da Próxima Steering"
                        name="next_date"
                        type="date"
                        onChange={handleChangeInput}
                        defaultValue={formatDate(steeringCurrent?.next_date as Date, 'numeric')}
                        useTooltipError={false}
                        error={errorMessageBuilder('next_date', errors)}
                    />
                </Grid>

                <Divider width='100%' />

                <H6>PARTICIPANTES DA STEERING:</H6>
                <AccordionInfoItem title='Participantes do Parceiro'>
                    <Grid row>
                        <ButtonAddCard text="Adicione Participante do Parceiro" handleOnClick={() => disabled ? undefined : navigate(pathParticipatingPartner)} />
                        {/* {steeringCurrent?.participating_alicerce &&
                            <GroupCard
                                group={steeringCurrent?.group}
                                inputCard={true}
                                customStyle={{ margin: '1rem' }}
                                canDeleteCard={{ removeCallback: () => disabled ? undefined : handleRemoveField('group') }}
                            />
                        } */}
                    </Grid>
                </AccordionInfoItem>

                <AccordionInfoItem title='Participantes Alicerce'>
                    <Grid row>
                        <ButtonAddCard text="Adicione Participante do Alicerce" handleOnClick={() => disabled ? undefined : navigate(pathParticipatingAlicerce)} />
                        {/* {steeringCurrent?.participating_alicerce &&
                            <GroupCard
                                group={steeringCurrent?.group}
                                inputCard={true}
                                customStyle={{ margin: '1rem' }}
                                canDeleteCard={{ removeCallback: () => disabled ? undefined : handleRemoveField('group') }}
                            />
                        } */}
                    </Grid>
                </AccordionInfoItem>
            </CustomForm>
        </Grid>
    );
}