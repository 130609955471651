import React, { useCallback } from 'react';

import IProjectReport from '@modules/projects/interfaces/IProjectReport';

import { ISelectOption } from 'react-alicerce-components';
import { useProjects } from '@modules/projects/context/ProjectsContext';

export const useProjectReportForm = () => {
    const { setReportCurrent } = useProjects();

    const handleChangeInput = useCallback(
        (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
            const inputValue = event.target.value;
            const inputName = event.target.name;
            setReportCurrent((oldState: IProjectReport) => ({ ...oldState, [inputName]: inputValue }));
        },
        [setReportCurrent]
    );

    const handleSelectChange = useCallback(
        (option: ISelectOption | null) => {
            if (!option) return
            setReportCurrent((oldState: IProjectReport) => ({ ...oldState, [String(option?.selectname)]: option.value }));
        },
        [setReportCurrent]
    );

    const handleRemoveAttach = (key: string) => { }

    return {
        handleChangeInput,
        handleSelectChange,
        handleRemoveAttach
    }
}
