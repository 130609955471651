import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Spinner, Tab, Tabs, useToast } from 'react-alicerce-components';
import { useLocation } from 'react-router-dom';

import HeaderModule from '@shared/components/Headers/HeaderModule';
import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import { Container } from './styles';
import { useClass } from '@modules/classes/context/ClassContext';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import GetClassService from '@modules/classes/services/GetClassService';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import ToggleFavoritedClassService from '@modules/classes/services/ToggleFavoritedClassService';
import ToggleFixedClassService from '@modules/classes/services/ToggleFixedClassService';
import { GaliaGpt } from '@shared/components/GaliaGpt';

interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [pathName, setPathName] = useState<string>();

  const { classCurrent, setClassCurrent, clearClassContextData, pathToReturn } = useClass();
  const navigate = useNavigate();
  const { newCancelToken } = useCancelRequest();
  const { setIsLoading, isLoading } = useGlobal();
  const classCurrentIdInPath = useParams<{ id: string }>();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const isMounted = useIsMounted();
  const { state: stateLocation } = useLocation() as { state: { pathToReturn: string } };

  const toggleFavorite = useCallback(async () => {
    if (!classCurrentIdInPath.id) return;
    setIsLoading(true);
    const res = await new ToggleFavoritedClassService(signedUser?.token).execute(classCurrentIdInPath.id);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Turma',
        description: 'Falha ao favoritar a turma.',
      });
      return;
    }
    setClassCurrent((oldState: any) => {
      return { ...oldState, favorited: res.favorited };
    });
    if (!isMounted) return;
  }, [addToast, classCurrentIdInPath.id, isMounted, setClassCurrent, setIsLoading, signedUser]);

  const toggleFixed = useCallback(async () => {
    if (!classCurrentIdInPath.id) return;
    setIsLoading(true);
    const res = await new ToggleFixedClassService(signedUser?.token).execute(classCurrentIdInPath.id);
    setIsLoading(false);
    if (!res) {
      addToast({
        status: 'danger',
        title: 'Turma',
        description: 'Falha ao fixar a turma.',
      });
      return;
    }
    setClassCurrent((oldState: any) => {
      return { ...oldState, fixed: res.fixed };
    });
    if (!isMounted) return;
  }, [addToast, classCurrentIdInPath.id, isMounted, setClassCurrent, setIsLoading, signedUser]);

  const handleGoBack = useCallback(() => {
    clearClassContextData();
    const returnPathFromLocationState = stateLocation ? (stateLocation.pathToReturn as string | undefined) : pathToReturn;
    navigate(returnPathFromLocationState ? returnPathFromLocationState : '/diretorio/turmas');
  }, [clearClassContextData, navigate, stateLocation, pathToReturn]);

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  const getClass = useCallback(async () => {
    if (!classCurrentIdInPath.id) return navigate('/minhas-turmas');
    setIsLoading(true);
    const res = await new GetClassService(signedUser?.token, newCancelToken()).execute(classCurrentIdInPath.id);
    setIsLoading(false);
    if (!res) return navigate('/minhas-turmas');
    setClassCurrent(res);
  }, [classCurrentIdInPath.id, navigate, newCancelToken, setClassCurrent, setIsLoading, signedUser]);

  useEffect(() => {
    if (classCurrent.id && classCurrentIdInPath.id === classCurrent.id) return;
    getClass();
  }, [classCurrent.id, classCurrentIdInPath.id, getClass]);

  useEffect(() => {
    setPathName(window.location.pathname);
  }, []);

  const canUserAccessGalia = useMemo(() => {
    if (signedUser?.profile_names.includes("GIP Acesso ao GPT")) return true
    if (signedUser?.is_admin) return true
    return false
  } ,[signedUser])

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderModule
        goBackCallback={handleGoBack}
        titleColor="#E9D7F9"
        title="TURMA"
        subtitle={`${classCurrent.name ? classCurrent.name : 'Carregando...'}`}
        additionalInfo={`${classCurrent.id ? classCurrent.id : 'Carregando...'}`}
        status="quaternary"
        useDefaultNav
        key={`${classCurrent.favorited}`}
        isScrolled={isScrolled}
        compressOnScroll
        menuKebab={{
          favorited: { favoritedCallback: toggleFavorite, isFavorited: classCurrent.favorited || false },
          attached: { attachedCallback: toggleFixed, isAttached: classCurrent.fixed || false },
          tooltip: { view: pathName?.includes('agenda') ? 'ClassSchedule' : 'ClassHome' },
          menuBackHome: true,
        }}
      />
      <CustomTabs footer={footer} handleScrollChange={handleScrollChange} dynamicHeaderHeight={190}>
        <Tabs openTab={openTab} content={children} horizontalScroll>
          <Tab title="Início" onClick={() => navigate(`/turma/${classCurrentIdInPath.id}`)} />
          <Tab title="Informações" onClick={() => navigate(`/turma/${classCurrentIdInPath.id}/informacoes`)} />
          <Tab title="Agenda" onClick={() => navigate(`/turma/${classCurrentIdInPath.id}/agenda`)} />
          <Tab title="Histórico" onClick={() => navigate(`/turma/${classCurrentIdInPath.id}/historico`)} />
          <Tab title="Relatórios" onClick={() => navigate(`/turma/${classCurrentIdInPath.id}/relatorios`)} />
        </Tabs>
      </CustomTabs>
      
      {canUserAccessGalia && <GaliaGpt module_id={`${classCurrentIdInPath.id}`} module_name='class' />}
    </Container>
  );
};

export default Layout;
