import React from 'react';

import { Grid, Input, Select, Textarea, formatDate } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import { IInputErrorsFormat, errorMessageBuilder } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';

import { useProjectReportForm } from '../useProjectReportForm';
import { defaultProjectReportType } from '@modules/projects/schemas/ProjectReport/partials/ProjectReportType';

interface ProjectReportInfoFragmentProps {
    disabled?: boolean
    errors?: IInputErrorsFormat[]
}

export const ProjectReportInfoFragment: React.FC<ProjectReportInfoFragmentProps> = ({
    errors,
    disabled
}) => {

    const { reportCurrent } = useProjects();

    const {
        handleSelectChange,
        handleChangeInput,
    } = useProjectReportForm()

    return (
        <Grid row>
            <CustomForm status="primary">
                <Grid row>
                    <Grid sm={12} md={5} mt4>
                        <Input
                            disabled={disabled}
                            label="Data do Report"
                            name="date"
                            type="date"
                            onChange={handleChangeInput}
                            defaultValue={formatDate(reportCurrent?.date as Date, 'numeric')}
                            useTooltipError={false}
                            error={errorMessageBuilder('date', errors)}
                        />
                    </Grid>
                    <Grid sm={12} md={7} mt4>
                        <Select
                            disabled={disabled}
                            label="Tipo da Steering:"
                            iconName="arrow-down"
                            placeholder="Selecione"
                            handleSelectChange={handleSelectChange}
                            options={defaultProjectReportType(reportCurrent?.type)}
                            error={errorMessageBuilder('type', errors)}
                            name='type'
                        />
                    </Grid>
                </Grid>

                <Grid row mt4>
                    <Textarea
                        disabled={disabled}
                        name="description"
                        label="Descrição do Report:"
                        placeholder="Adicione a Descrição do Report"
                        onChange={handleChangeInput}
                        value={reportCurrent?.description}
                        rows={25}
                    />
                </Grid>
            </CustomForm>
        </Grid>
    );
}