import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import SteeringForm from '@modules/projects/components/SteeringForm';

const SteeringInfo: React.FC = () => {
  const navigate = useNavigate();
  const { steeringCurrent, getSteering } = useProjects();

  useEffect(() => { getSteering('') }, [getSteering])
  return (
    <SteeringForm
      headerTitle="Steering"
      footer={
        <Button
          transform="none"
          shaded
          onClick={() => navigate(`/diretorio/projetos/steering/${steeringCurrent?.id}/editar`)}
        >
          Editar
        </Button>
      }
    />
  );
};

export default SteeringInfo;
