import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import ContractList from '@modules/contracts/components/ContractList';
import IContract from '@modules/contracts/interfaces/IContract';
import ListContractsService, { IListContractBusinessOptions, IContractResponse } from '@modules/contracts/services/ListContractService';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import useAuth from '@shared/store/auth/hook';
import { getLocalTimezoneDate } from '@shared/utils/getLocalTimezoneDate';
import { ContractDefaultStylesContainer } from '@modules/contracts/components/ContractDefaultStyles/styles';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import { Spinner, Grid } from 'react-alicerce-components';

const ProjectContractRelation: React.FC = () => {
  const contractBusinessFilter = useRef<IListContractBusinessOptions>({ end_date: { end: undefined, start: undefined } });
  const { isLoading,setIsLoading } = useGlobal();
  const { newCancelToken } = useCancelRequest();
  const { signedUser } = useAuth();
  const [contractList, setContractList] = useState<IContractResponse>()
  const listContracts = useCallback(
    async (page = 0) => {
      const start = contractBusinessFilter.current.end_date?.start
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.start).startOfDay().toISOString()
        : undefined

      const end = contractBusinessFilter.current.end_date?.end
        ? getLocalTimezoneDate(contractBusinessFilter.current.end_date?.end).endOfDay().toISOString()
        : undefined

      if (!signedUser) return;
      setIsLoading(true);
      const contracts = await new ListContractsService(signedUser.token, newCancelToken()).execute('', {
        page,
        size: 10,
        ...contractBusinessFilter.current,
        end_date: { start, end }
      });
      if (contracts === 'canceling') return;
      setIsLoading(false);
      if (!contracts) return;
      setContractList(contracts);
    },
    [newCancelToken, setIsLoading, signedUser]
  );

  useEffect(() => {
    listContracts()
  }, [listContracts])

  const { setProjectCurrent, projectCurrent } = useProjects();
  const [selectedContract, setSelectedContractCreate] = useState<IContract>();

  useEffect(() => {
    setSelectedContractCreate(projectCurrent?.contract);
  }, [projectCurrent]);

  const handleAddRelationContract = useCallback(
    (contractSelect: IContract | undefined) => {
      setProjectCurrent((oldState: any) => ({ ...oldState, gif_contract_business: contractSelect }));
    },
    [setProjectCurrent]
  );

  const setSelectedItens = useCallback(
    (contract: IContract[]) => {
      setSelectedContractCreate(contract[0]);
    },
    [setSelectedContractCreate]
  );

  return <ContractDefaultStylesContainer>
    <HeaderDirectory

      title='Diretório de Contrato'
      menuKebab={{ tooltip: { view: 'ContractDirectory' }, menuBackHome: true }}
    />
    {isLoading && <Spinner fixed />}
    <DirectoryContainer footer={true}>
      <Grid container>
        <ContractList
          contractList={contractList}
          btnText="Adicionar Contrato"
          listContracts={listContracts}
          contractBusinessFilter={contractBusinessFilter}
          selectedItens={selectedContract ? [selectedContract] : []}
          setSelectedItens={setSelectedItens}
          handleOnClick={handleAddRelationContract}
          canSelectContract="one"
        />
      </Grid>
    </DirectoryContainer>
  </ContractDefaultStylesContainer>
};

export { ProjectContractRelation };