import React from 'react';

import { Grid, H6, Textarea } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { useProjectReportForm } from '../useProjectReportForm';

interface ProjectReportAttachmentsProps {
    disabled?: boolean
}

export const ProjectReportAttachments: React.FC<ProjectReportAttachmentsProps> = ({
    disabled,
}) => {

    const { steeringCurrent } = useProjects();
    const { handleChangeInput } = useProjectReportForm();

    return (
        <Grid row mt4>
            <H6>ATA DA STEERING:</H6>
            <Textarea
                disabled={disabled}
                name="ata"
                label="Ata:"
                placeholder="Adicione a ata da Steering"
                onChange={handleChangeInput}
                value={steeringCurrent?.ata}
                rows={25}
            />
        </Grid>
    );
}