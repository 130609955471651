import styled from 'styled-components';

export const Container = styled.div<{ calculateTabPanelHeight: number }>`
  margin-top: -42px;
  position: absolute;
  left: 0;
  right: 0;
  /* z-index: 4; */

  [role='tablist-container'] {
    padding: 0 !important;
  }

  [role='tabpanel'],
  [role='tabpanel-container'] {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  [role='tablist'] {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border-bottom: none;
    padding-top: 0.5rem !important;

    [role='tab'] {
      background-color: #e4e9f2;
      border-bottom: none;
      margin: 0;
      border-left: 1px solid #c5cee0;
      padding: 0.5rem 8px;
    }

    [role='tab'] span {
      font-size: 10px;
      text-transform: uppercase;
      color: #8f9bb3;
      font-family: var(--secondary-font);
    }

    [role='tab'].is-active {
      background-color: #fff;
    }

    [role='tab'].is-active span {
      color: #222b45;
    }

    [role='tab']:first-child {
      border-top-left-radius: 4px;
    }

    [role='tab']:last-child {
      border-top-right-radius: 4px;
    }
  }

  [role='tabpanel'] {
    background-color: #fff;
    height: ${({ calculateTabPanelHeight }) => `calc(100vh - ${calculateTabPanelHeight}px) !important`};
    overflow-y: auto;
    padding: 45px 22px 30px 22px;
  }

  @media (max-width: 360px) {
    [role='tablist'] [role='tab'] {
      padding: 0.5rem 6px;

      .button-inner span {
        margin-left: 0px;
      }
    }
  }

  @media (max-width: 315px) {
    [role='tablist'] {
      [role='tab'] {
        padding: 0.5rem 2px;
        .button-inner {
          span {
            margin-left: 0px;
          }
        }
      }
    }
  }

  // RESPONSIVE - DESKTOP
  @media (min-width: 992px) {
    [role='tablist'] {
      background: #fff !important;
      padding: .5rem 0;
      [role='tab'] span {
        font-size: 1.2rem;
      }
    }

    [role='tabpanel'] {
      border-radius: 0!important;
    }
  }
`;
