export const STEERING_CHANNEL_ZOOM = { name: 'Zoom', value: 'zoom' };
export const STEERING_CHANNEL_MEET = { name: 'Meet', value: 'meet' };
export const STEERING_CHANNEL_TEAMS = { name: 'Teams', value: 'teams' };
export const STEERING_CHANNEL_IN_PERSON = { name: 'Presencial', value: 'in_person' };

export const STEERING_CHANNEL_CONSTRAINTS = [STEERING_CHANNEL_ZOOM, STEERING_CHANNEL_MEET, STEERING_CHANNEL_TEAMS, STEERING_CHANNEL_IN_PERSON];

export const CHANNEL_STEERING_VALUES = STEERING_CHANNEL_CONSTRAINTS.map((c) => c.value);

export const defaultSteeringChannel = (steeringChannel?: string | null) => STEERING_CHANNEL_CONSTRAINTS.map(i => ({ ...i, selected: steeringChannel === i.value }))
