import React from 'react';
import { Grid, GridName, CategoryStatus, GridsContainer, ActiveContainer, CategoriesContainer } from './styles';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { getDay } from '@shared/utils/getDay';
import { useHeadlights } from '@modules/projects/components/Headlights/context/HeadlightsContext';
import { IPeriod } from '@modules/schedules/interfaces/IPeriod';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';

type GridsProps = {
  sprintHeadlights?: IHeadlight[];
  sprint: ISprint;
};

export const Grids: React.FC<GridsProps> = ({sprintHeadlights, sprint}) => {
  const { currentPeriod, setCurrentPeriod } = useHeadlights();

  const changePeriodSelected = (period: IPeriod) => {
    setCurrentPeriod(period)
  }

  return (
    <GridsContainer className="grids-container">
      {sprint?.periods?.map((period, gridIndex) => (
        <Grid id={`grid-${gridIndex}`} key={`grid-${gridIndex}`} className="grid">
          {(
            <>
              <ActiveContainer
                className="active-container"
                periodSelected={period.id === currentPeriod?.id}
                onClick={() => changePeriodSelected(period)}
              >
                <GridName className="grid-name">
                  <h6>{period?.name}</h6>
                  <p>{getDay(period.start_date)}</p>
                  <p>{getDay(period.end_date)}</p>
                </GridName>
                <CategoriesContainer className="categories-container">
                  {sprintHeadlights
                  ?.find((h) => h.period_id === period.id)
                  ?.data?.map((d, i) => (
                    <CategoryStatus key={`category-${i}`} status={d.status}>{d.category}</CategoryStatus>
                  ))}
                </CategoriesContainer>
              </ActiveContainer>
              {/* <Markers gridIndex={gridIndex} blockName={block?.name} /> */}
            </>
          )}
        </Grid>
      ))}
    </GridsContainer>
  );
};

