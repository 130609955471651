export const PROJECT_REPORT_TYPE_IMPLANTATION = { name: 'Implementação', value: 'implantation' };
export const PROJECT_REPORT_TYPE_EXECUTION = { name: 'Execução', value: 'execution' };
export const PROJECT_REPORT_TYPE_RESULTS = { name: 'Resultados', value: 'results' };
export const PROJECT_REPORT_TYPE_RENOVATION = { name: 'Renovação', value: 'renovation' };
export const PROJECT_REPORT_TYPE_KICKOFF = { name: 'Kickoff', value: 'kickoff' };


export const PROJECT_REPORT_TYPE_CONSTRAINTS = [PROJECT_REPORT_TYPE_IMPLANTATION, PROJECT_REPORT_TYPE_EXECUTION, PROJECT_REPORT_TYPE_RESULTS, PROJECT_REPORT_TYPE_RENOVATION, PROJECT_REPORT_TYPE_KICKOFF];

export const TYPE_PROJECT_REPORT_VALUES = PROJECT_REPORT_TYPE_CONSTRAINTS.map((c) => c.value);

export const defaultProjectReportType = (projectReportType?: string | null) => PROJECT_REPORT_TYPE_CONSTRAINTS.map(i => ({ ...i, selected: projectReportType === i.value }))
