import React from 'react';
import { Container, Header, Wrapper } from './styles';
import { Grids } from '../Grids';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import { getDay } from '@shared/utils/getDay';
import IHeadlight from '@modules/projects/interfaces/IHeadlight';
import { P } from 'react-alicerce-components';

type SprintHeadlightsContainerProps = {
  sprint?: ISprint;
  sprintHeadlights?: IHeadlight[]
};

const SprintHeadlightsContainer: React.FC<SprintHeadlightsContainerProps> = ({ sprint, sprintHeadlights }) => {
  return (
    <Container className="headlights">
      {
        sprint &&
        <Wrapper
          className="headlights-wrapper"
        >
          <Header>
            <h6>{sprint.name}</h6>
            <p>{getDay(sprint.start_date)} - {getDay(sprint.end_date)}</p>
          </Header>
          {sprint.periods?.length === 0
            ?
            <P textAlign='center' marginTop='110px'>Nenhum dado para exibir</P>
            :
            <Grids sprintHeadlights={sprintHeadlights} sprint={sprint} />}
        </Wrapper>
      }
    </Container>
  );
};

export { SprintHeadlightsContainer };
