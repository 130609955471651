import React from 'react';
import { IButtonProps } from 'react-alicerce-components';

import ButtonGradient from "../ButtonGradient";

type ButtonNavigationToGIPProps = IButtonProps & {
  type?: number;
  to: string
}

const ButtonNavigationToGIP: React.FC<ButtonNavigationToGIPProps> = ({ to, children, type, ...buttonProps }) => {
  const env = process.env.REACT_APP_LINK_GIP

    if (!env) {
      throw new Error('REACT_APP_LINK_GIP is not setup.')
    }

    return (
      <a href={`${env}${to}`}>
        <ButtonGradient type={type} {...buttonProps}>{children}</ButtonGradient>
      </a>
    );
}

export default ButtonNavigationToGIP;