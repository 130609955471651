import React, { useMemo, useCallback } from 'react';
import Icon from 'react-eva-icons';

// import IMedia from '@shared/interfaces/IMedia';
import { TextEllipsis } from 'react-alicerce-components';

import { Container, DeleteIconContainer, IconContainer, InfoContainer, Name } from './styles';
import { IAttachment } from '../../interfaces/IAttachment';

export interface IMediaAttachProps {
  media: File | IAttachment;
  iconName?: string;
  handleOnDelete?: () => void;
  handleOnClick?: () => void;
  colorBackgroundIcon?: string;
  colorBackgroundText?: string;
}

const MediaAttach: React.FC<IMediaAttachProps> = ({ media, iconName, handleOnDelete, colorBackgroundIcon, colorBackgroundText, handleOnClick }) => {
  const getIconName = useMemo(() => {
    return iconName ? iconName : 'file-outline';
  }, [iconName]);

  const handleDelete = useCallback(() => {
    if (handleOnDelete) {
      handleOnDelete();
    }
  }, [handleOnDelete]);

  return (
    <Container style={{ cursor: handleOnClick ? 'pointer' : 'default' }} onClick={handleOnClick}>
      {handleOnDelete && (
        <DeleteIconContainer onClick={handleDelete}>
          <Icon name="close" />
        </DeleteIconContainer>
      )}
      <IconContainer color={colorBackgroundIcon}>
        <Icon name={getIconName} />
      </IconContainer>
      <InfoContainer color={colorBackgroundText}>
        <Name>
          <TextEllipsis lineClamp={3} lineHeight={0} boxOrient="vertical">
            {media.name}
          </TextEllipsis>
        </Name>
      </InfoContainer>
    </Container>
  );
};

export default MediaAttach;
