import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import { Spinner, Tab, Tabs } from 'react-alicerce-components';

import CustomTabs, { ICustomTabsScrollContainerChange } from '@shared/components/CustomTabs';

import HeaderModule from '@shared/components/Headers/HeaderModule';
import { useGlobal } from '@shared/contexts/global/GlobalContext';

import { useProjects } from '@modules/projects/context/ProjectsContext';

import { Container } from './styles';
import { translateProjectStatus } from '@modules/projects/schemas/Project/partials/ProjectStatus';


interface ILayoutProps {
  openTab?: number;
  footer?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({ openTab = 0, children, footer }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();
  const { isLoading } = useGlobal();
  const { projectCurrent, getProject } = useProjects();
  const { state: stateLocation } = useLocation() as { state: { pathToReturn: string } };

  const loadProject = useCallback(async () => {
    if (!id) return;
    const res = await getProject(id);
    if (!res) navigate('/diretorio/projeto');
  }, [id, getProject, navigate]);

  useEffect(() => {
    if (id && (!projectCurrent || id !== projectCurrent.id)) {
      loadProject();
    }
  }, [loadProject, id, projectCurrent]);

  const handleScrollChange = useCallback((data: ICustomTabsScrollContainerChange) => {
    setIsScrolled(data.isScrolled);
  }, []);

  const handleGoBack = useCallback(() => {
    const returnPathFromLocationState = stateLocation && (stateLocation.pathToReturn as string | undefined);
    navigate(returnPathFromLocationState ? returnPathFromLocationState : '/diretorio/projetos');
  }, [navigate, stateLocation]);

  return (
    <Container>
      {isLoading && <Spinner />}
      <HeaderModule
        key={`${projectCurrent}`}
        title="Projeto"
        subtitle={`${projectCurrent?.name || 'Carregando...'}`}
        additionalInfo={`Status: ${translateProjectStatus(projectCurrent?.status)}`}
        useDefaultNav
        goBackCallback={handleGoBack}
        titleColor="#F3C8C8"
        bgHeaderColor='linear-gradient(45deg, #BE3A3A 0%, #F7652B 100%)'
        isScrolled={isScrolled}
        compressOnScroll
        menuKebab={{
          tooltip: { view: 'PlaceHome' },
          menuBackHome: true,
        }}
      />

      <CustomTabs
        footer={footer || false}
        handleScrollChange={handleScrollChange}
        dynamicHeaderHeight={190}
      >
        {projectCurrent && (
          <Tabs openTab={openTab} content={children} horizontalScroll>
            <Tab title="Informações" onClick={() => navigate(`/projeto/${id}/informacoes`)} />
            <Tab title="Turmas" onClick={() => navigate(`/projeto/${id}/turmas`)} />
            <Tab title="Faróis" onClick={() => navigate(`/projeto/${id}/farois`)} />
            <Tab title="Steerings" onClick={() => navigate(`/projeto/${id}/steerings`)} />
            <Tab title="Horas Gastas" onClick={() => navigate(`/projeto/${id}/horas-gastas`)} />
          </Tabs>
        )}
      </CustomTabs>
    </Container>
  );
};

export default Layout;
