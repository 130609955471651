import styled from 'styled-components';

export const ClassFormContainer = styled.div`
  .select-container {
    .select-options-list {
      position: relative;
    }
  }

  .checkbox-container {
    margin: 0;
    > label {
      margin: 0;
      > div {
        margin: 0 4px 0 0;
      }
    }
  }
`;

export const AgeGroupClass = styled.div`
  margin: 1.5rem 0 0 0;

  .all-age-groups-checkbox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;

    > div {
      margin: 4px 8px 0 0;
      > label {
        > div {
          margin: 4px 4px 0 4px;
        }
      }
    }
  }

  .title {
    color: #2e3a59;
    font-family: var(--secondary-font);
    text-transform: unset;
    margin: 4px 0;
    font-size: 14px;
    line-height: 24px;
  }

  .error-checkbox-age-groups {
    color: #c53030;
    font-size: 12px;
    font-weight: 600;
  }
`;
