import React from 'react';
import { ICardProps as IProps } from 'react-alicerce-components';
import { ElementStatus } from 'react-alicerce-components/dist/shared/theme/colors';
import Icon from 'react-eva-icons';

import { Container, StyledCard } from './styles';

export type ICustomCardProps = IProps & {
  notification?: string;
  notificationBgColor?: string;
  notificationColor?: string;
  statusBody?: ElementStatus;
  statusFooter?: ElementStatus;
  footerTags?: string | JSX.Element | undefined;
  outlined?: boolean;
  handleOnClick?: () => void;
  inputCard?: boolean;
  className?: string;
  canDeleteCard?: { indexId?: number; removeCallback: (indexId?: number) => void };
};

const CustomCard: React.FC<ICustomCardProps> = ({
  inputCard,
  statusBody,
  statusFooter,
  footerTags,
  notification,
  notificationBgColor = '#F42727',
  notificationColor = '#FFF',
  outlined = false,
  handleOnClick,
  canDeleteCard,
  className,
  ...rest
}) => {
  return (
    <Container
      className={`custom-card ${className || ''}`}
      notificationColor={notificationColor}
      notificationBgColor={notificationBgColor}
      onClick={handleOnClick}
      inputCard={inputCard}
      canDeleteCard={canDeleteCard}
    >
      {notification && <div className="notification-card">{notification}</div>}

      <StyledCard outlined={outlined} statusBody={statusBody} statusFooter={statusFooter} {...rest} />
      

      {canDeleteCard && (
        <div className="remove-card" onClick={() => canDeleteCard.removeCallback()}>
          <Icon name="close" fill="#FFFFFF" />
        </div>
      )}
    </Container>
  );
};

export default CustomCard;
