import styled from 'styled-components';
import { Card } from 'react-alicerce-components';
import themeGet from '@styled-system/theme-get';
import { ICustomCardProps } from './index';

export const Container = styled.div<ICustomCardProps>`
  position: relative;
  cursor: ${(p) => (p.inputCard ? 'default !important' : 'pointer')};

  .remove-card {
    cursor: pointer;
    width: 30px;
    aspect-ratio: 1;
    background: red;
    border-radius: 100px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      display: flex;
      align-items: center;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  ${(p) =>
    p.inputCard &&
    `   
        background: #F7F9FC;
        border: 2px solid #E4E9F2;
        border-radius: 5px;
        margin-top: 4px;
        .remove-card{
            position: absolute;
            top: 1rem;
            right: 1rem;
            transform: translate(35%,-35%);
        }
        `}

  ${(p) =>
    p.canDeleteCard &&
    !p.inputCard &&
    `   display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 30px 0px 0px 0px;

        > div:first-child {
          margin: 0;
          width: calc(100% - 20px - 30px);
        }
    `}

    .notification-card {
    background: ${({ notificationBgColor }) => notificationBgColor};
    color: ${({ notificationColor }) => notificationColor};

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -14px;
    right: 14px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.22;
    z-index: 1;
  }
`;

export const StyledCard = styled(Card)<ICustomCardProps>`
  margin-top: 2rem;
  border-radius: 0.31rem;

  .card-header {
    text-align: center;
    position: relative;
    background-color: ${({ status }) => (status === 'basic' ? themeGet(`colors.${status}600`) : themeGet(`colors.${status}400`))};

    border-bottom-color: ${({ status }) => (status === 'basic' ? themeGet(`colors.${status}600`) : themeGet(`colors.${status}400`))};

    color: #fff;
    padding: 0.5rem 1rem;
    border-top-left-radius: 0.31rem;
    border-top-right-radius: 0.31rem;
    font-weight: 800;

    h6 {
      font-family: var(--primary-font);
      font-weight: 800;
      font-size: 1rem;
      text-align: center;
    }
  }

  .card-body {
    border-bottom-left-radius: 0.31rem;
    border-bottom-right-radius: 0.31rem;
    display: flex;
    justify-content: center;
    background-color: ${({ status, statusBody }) =>
      statusBody ? themeGet(`colors.${statusBody}400`) : status === 'basic' ? themeGet(`colors.${status}300`) : themeGet(`colors.${status}100`)};

    ${(props) =>
      props.outlined &&
      `
           outline: 2px solid ${themeGet(`colors.${props.statusBody}500`)(props)};
           outline-offset: -2px;
        `}

    >div {
      text-align: center;
    }
  }

  .card-footer {
    //padding: 7px 5px 5px 5px;
    padding: 0%;
    min-height: 34px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    ${(p) => p.statusFooter && `background-color: ${themeGet(`colors.${p.statusFooter}400`)(p)}`}
  }
`;
