import React, { useCallback, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { formatProject } from '@modules/projects/utils/formatProject';
import { ProjectSchema } from '@modules/projects/schemas/Project';
import ProjectReportForm from '@modules/projects/components/ProjectReportForm';

const ProjectReportCreate: React.FC = () => {
  const dirPath = '/diretorio/projetos/report'

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { clearReportCreate, reportCurrent } = useProjects();

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    clearReportCreate();
    navigate(dirPath);
  }, [clearReportCreate, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!reportCurrent) return;
    const formatedBody = formatProject({} as any);
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [reportCurrent]);

  const handleCreateProjectReport = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !reportCurrent) return;
    setIsLoading(true);
    // const formatedBody = formatProject(reportCurrent);
    // const res = await new CreatePlaceService(signedUser.token).execute(formatedBody);
    const res = await true
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Report',
        description: 'Report criada com sucesso.',
      });
      clearReportCreate();
      return navigate(dirPath);
    }

    addToast({
      status: 'danger',
      title: 'Report',
      description: 'Não foi possível criar a Report.',
    });
  }, [addToast, clearReportCreate, navigate, setIsLoading, reportCurrent, signedUser]);

  return (
    <Fragment>
      <ProjectReportForm
        headerTitle="Criar Report"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar este Report?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleCreateProjectReport}
      />
    </Fragment>
  );
};

export default ProjectReportCreate;
