import React from 'react';

import { formatDate, P, Pill } from 'react-alicerce-components';
import CustomCard, { ICustomCardProps } from '@shared/components/CustomCard';

import Tag from '@modules/tags/components/Tag';

import { Container, FooterContainer } from './styles';

type ISteeringCardProps = ICustomCardProps & {
  steering?: any;
};

const SteeringCard: React.FC<ISteeringCardProps> = ({ steering, ...rest }) => {
  return (
    <Container className="steering-card-container">
      <CustomCard
        header={
          <h6>
            <b>Temperatura:</b>
            <Pill
              status='success'
              size='small'
              marginLeft={1}
            >
              Positiva
            </Pill>
          </h6>
        }
        footer={
          <FooterContainer>
            <div>
              <b>Canal:</b>
              <Tag
                tag={{ name: 'Presencial' }}
                size="small"
              />
            </div>
            <div>
              <b>Tipo:</b>
              <Tag
                tag={{ name: 'Acompanhamento' }}
                size="small"
              />
            </div>
          </FooterContainer>
        }
        {...rest}
      >
        <P>
          Data de realização:<br />
          <span>
            {formatDate(new Date('2023-12-25'), 'long')}
          </span>
        </P>

      </CustomCard>
    </Container>
  );
};

export default SteeringCard;
