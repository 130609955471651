import React from 'react';

import { Grid, H6, Textarea } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { useSteeringForm } from '../useSteeringForm';

interface SteeringActionPlanFragmentProps {
    disabled?: boolean
}

export const SteeringActionPlanFragment: React.FC<SteeringActionPlanFragmentProps> = ({
    disabled,

}) => {

    const { steeringCurrent } = useProjects();
    const { handleChangeInput } = useSteeringForm();

    return (
        <Grid row mt4>
            <H6>PLANO DE AÇÃO DA STEERING:</H6>
            <Textarea
                disabled={disabled}
                name="action_plan"
                label="Plano de Ação:"
                placeholder="Adicione o Plano de Ação da Steering"
                onChange={handleChangeInput}
                value={steeringCurrent?.action_plan}
                rows={25}
            />
        </Grid>
    );
}