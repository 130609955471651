import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Layout from '@modules/projects/components/Layout';
import { useProjects } from '@modules/projects/context/ProjectsContext';
import { ProjectDefaultStylesContainer } from '@modules/projects/components/ProjectDefaultStyles/styles';
import { Headlights } from '@modules/projects/components/Headlights';
import { Divider, Grid, ISelectOption, Radio, Select, Button } from 'react-alicerce-components';
import { ISprint } from '@modules/schedules/interfaces/ISprint';
import useAuth from '@shared/store/auth/hook';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import ListSprintsService from '@modules/schedules/services/ListSprintsService';
import ListHeadlightsService, { IHeadlightResponse } from '@modules/projects/services/Headlight/ListHeadlightsService';
import { HeadlightType } from '@modules/projects/schemas/Headlight/partials/HeadlightType';
import { Container, HeadlightsPicker } from './styles';
import { Diagnostics } from '@modules/projects/components/Diagnostics';
import PageFooter from '@shared/components/PageFooter';
import { useNavigate } from 'react-router-dom';

const ProjectHeadlights: React.FC = () => {
  const { projectCurrent } = useProjects();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const [sprints, setSprints] = useState<(ISprint)[]>([]);
  const [selectedSprint, setSelectedSprint] = useState<(ISprint)>();
  const [sprintHeadlights, setSprintHeadlights] = useState<(IHeadlightResponse)>();
  const [headlightType, setHeadlightType] = useState<(HeadlightType)>('deployment');
  const [openTab, setOpenTab] = useState<'headlight' | 'diagnostic'>();
  const [selectedTab, setSelectedTab] = useState<string>(openTab ? openTab : 'headlight');
  const navigate = useNavigate();

  const listSprints = useCallback(async (scheduleId) => {
    if (!signedUser) return;

    setIsLoading(true);

    const sprints = await new ListSprintsService(signedUser.token).execute('', { schedule_id: scheduleId, periods: true });

    const isCancel = sprints === 'canceling';
    const isFail = !sprints;

    if (isFail || isCancel) return;

    setSprints([...sprints.items]);

    setIsLoading(false);
  }, [setIsLoading, signedUser]);

  useEffect(() => {
    listSprints(projectCurrent?.schedule_id)
  }, [listSprints, projectCurrent]);

  const defaultSprintOptions = (sprints: ISprint[]): ISelectOption[] | undefined => {
    return sprints.map((sprint) => ({ name: sprint.name, value: String(sprint.id) }))
  };

  const handleSelectChange = useCallback(
    (option, sprints: ISprint[]) => {
      const selectedSprint = sprints.find(sprint => sprint.id === option.value);
      setSelectedSprint(selectedSprint);
    },
    [setSelectedSprint]
  );

  const getHeadlight = useCallback(async (selectedSprint, projectCurrent, headlightType) => {
    if (!signedUser) return;

    setIsLoading(true);

    if (selectedSprint && projectCurrent && headlightType) {
      const headlights = await new ListHeadlightsService(signedUser.token).execute({ sprint_id: selectedSprint.id, project_id: projectCurrent.id, type: headlightType });
      const isCancel = headlights === 'canceling';
      const isFail = !headlights;

      if (isFail || isCancel) return;

      setSprintHeadlights(headlights);
    }
    setIsLoading(false);
  }, [setIsLoading, signedUser]);


  useEffect(() => {
    getHeadlight(selectedSprint, projectCurrent, headlightType)
  }, [selectedSprint, projectCurrent, headlightType, getHeadlight]);

  useEffect(() => {
    projectCurrent?.status === 'execution' && setHeadlightType('execution')
  }, [projectCurrent]);

  const handleChangeTab = useCallback(
    (tabToRender: 'headlight' | 'diagnostic') => {
      setOpenTab(undefined);
      setSelectedTab(tabToRender);
    },
    [setOpenTab]
  );

  const renderedComponent = useMemo(() => {
    if (selectedTab === 'headlight') {
      return (
        <Headlights
          sprint={selectedSprint}
          sprintHeadlights={sprintHeadlights?.items || []}
        />
      );
    } else if (selectedTab === 'diagnostic') {
      return (
        <Diagnostics sprintHeadlights={sprintHeadlights} />
      );
    }
  }, [
    selectedSprint,
    sprintHeadlights,
    selectedTab,
  ]);

  return (
    <Layout openTab={2}>
      <ProjectDefaultStylesContainer>
        <Container>
          {projectCurrent?.status === 'execution' &&
            <>
              <Grid row justifyContentStart>
                <Grid column={6}>
                  <Radio
                    label='F. de Execução'
                    placeholder='F. de Execução'
                    round
                    name='headlight_status'
                    id='execution'
                    status='primary'
                    onChange={() => setHeadlightType('execution')}
                    defaultChecked={true} />
                </Grid>
                <Grid column={6}>
                  <Radio
                    label='F. de Implantação'
                    placeholder='F. de Implantação'
                    round
                    name='headlight_status'
                    id='deployment'
                    onChange={() => setHeadlightType('deployment')}
                    status='primary' />
                </Grid>
              </Grid>
              <Divider width='100%' />
            </>
          }
          <Select
            label="Sprint"
            iconName="arrow-down"
            placeholder="Selecione"
            fullWidth
            handleSelectChange={(option) => handleSelectChange(option, sprints)}
            options={defaultSprintOptions(sprints)}
          />
          <Divider width='100%' />
          {selectedSprint &&
            <HeadlightsPicker>
              <div className="headlight-picker-container">
                <div onClick={() => handleChangeTab('headlight')} className={`headlight-tab headlight ${selectedTab === 'headlight' ? 'selected' : ''}`}>
                  Faróis
                </div>
                <div onClick={() => handleChangeTab('diagnostic')} className={`headlight-tab diagnostic ${selectedTab === 'diagnostic' ? 'selected' : ''}`}>
                  Diagnósticos
                </div>
              </div>
            </HeadlightsPicker>
          }
          {renderedComponent}
        </Container>
        <PageFooter>
          <Button
            transform="none"
            shaded
            onClick={() => navigate(`/projeto/${projectCurrent?.id}/lancar-farol`)}
          >
            Lançar Farol
          </Button>
        </PageFooter>
      </ProjectDefaultStylesContainer>
    </Layout>
  );
};

export default ProjectHeadlights;
