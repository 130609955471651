import React from 'react';
import Icon from 'react-eva-icons';
import { Grid, P, Pill } from 'react-alicerce-components';

import { formatDefaultDate } from '@shared/utils/formatDate';

import { IStageOfLifeDefinition, IStudentLastGrades } from '@modules/students/interfaces/IStudent';

import { Container } from './styles';


export type LifeStageStudentCardProps = {
  lifeStage: IStageOfLifeDefinition;
  grades?: IStudentLastGrades[]
};

export const LifeStageStudentCard: React.FC<LifeStageStudentCardProps> = ({ lifeStage, grades }) => {
  const statusConfig = {
    updated: { label: 'Atualizado', bgColor: '#00D68F' },
    unchange: { label: 'Inalterado', bgColor: '#8F9BB3' },
    pending: { label: 'Pendente', bgColor: '#FF3D71' },
  };

  return (
    <Container>
      <Grid
        dFlex
        p2
        mt4
        bgColor={'#855CBD'}
        style={{
          color: '#fff',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <Pill backgroundColor={statusConfig[lifeStage.status].bgColor}>{statusConfig[lifeStage.status].label}</Pill>
        <Grid dFlex alignItemsCenter>
          <Grid ml2 color="$basic-100">
            última definição {formatDefaultDate({ date: lifeStage?.created_at, typeMonth: 'numeric' })}
          </Grid>
        </Grid>
      </Grid>

      <Grid p4 bgColor={'#E9D7F9'}>
        <Grid p1 >
          <Grid dFlex>
            <Icon name="navigation" fill="#2E3A59" />
            Estágio de Vida
          </Grid>
          <P fontWeight={700}>
            {lifeStage.stageOfLife?.name}
          </P>
        </Grid>
      </Grid>

      <Grid
        p2
        bgColor={'#855CBD'}
        style={{
          color: '#fff',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          }}
      >
        <Grid dFlex>
          <Icon name="award" fill="#fff" />
          Notas dos Últimos MAPAs
        </Grid>
        <Grid w100 dFlex justifyContentBetween>
          {grades && grades.map((grade, index) => 
            <Pill key={index} px={'16px'} backgroundColor={grade?.trail?.color || '#cecece'}>{grade.zdp_block.name}</Pill>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
