import React from 'react';

import {
  Button,
  Spinner,
  Tab,
  Tabs,
} from 'react-alicerce-components';

import { IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import CustomTabs from '@shared/components/CustomTabs';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import PageFooter from '@shared/components/PageFooter';

import { useProjects } from '@modules/projects/context/ProjectsContext';

import { ProjectReportInfoFragment } from './partials/ProjectReportInfoFragment';
import { ProjectReportAttachments } from './partials/ProjectReportAttachments';

import { ProjectDefaultStylesContainer } from '../ProjectDefaultStyles/styles';
import { ProjectReportFormContainer } from './styles';

interface ProjectReportFormProps {
  headerTitle: string;
  headerPathToReturn?: string;
  errors?: IInputErrorsFormat[];
  disabled?: boolean,
  footer?: JSX.Element,
  handleCancel?: () => void;
  deleteCallback?: () => void;
  handleConfirmButton?: () => Promise<void>;
}

const ProjectReportForm: React.FC<ProjectReportFormProps> = ({
  errors,
  footer,
  disabled,
  headerTitle,
  headerPathToReturn,
  handleCancel,
  deleteCallback,
  handleConfirmButton,
}) => {
  const { reportCurrent, clearReportCreate } = useProjects();

  const { isLoading } = useGlobal();

  console.log(' ~ reportCurrent', reportCurrent);

  return (
    <ProjectDefaultStylesContainer>
      <ProjectReportFormContainer>
        {isLoading && <Spinner />}
        <HeaderDirectory
          title={headerTitle}
          pathToReturn={headerPathToReturn}
          onClickReturn={clearReportCreate}
          menuKebab={{ deleteCallback }}
        />
        <DirectoryContainer footer={true}>
          <CustomTabs footer>
            <Tabs horizontalScroll>
              <Tab title="Informação">
                <ProjectReportInfoFragment errors={errors} disabled={disabled} />
              </Tab>
              <Tab title="Anexos">
                <ProjectReportAttachments disabled={disabled} />
              </Tab>
            </Tabs>
          </CustomTabs>
          <PageFooter>
            {footer}
            {!footer &&
              <>
                <Button
                  className='btn-cancel'
                  transform="none"
                  onClick={handleCancel}
                  shaded
                  outline
                >
                  Cancelar
                </Button>
                <Button
                  transform="none"
                  shaded
                  noBorder
                  onClick={handleConfirmButton}
                >
                  Salvar
                </Button>
              </>
            }
          </PageFooter>
        </DirectoryContainer>
      </ProjectReportFormContainer>
    </ProjectDefaultStylesContainer>
  );
};

export default ProjectReportForm;
