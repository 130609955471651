import React, { useCallback, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import useAuth from '@shared/store/auth/hook';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';


import { useProjects } from '@modules/projects/context/ProjectsContext';
import { formatProject } from '@modules/projects/utils/formatProject';
import { ProjectSchema } from '@modules/projects/schemas/Project';
import SteeringForm from '@modules/projects/components/SteeringForm';

const SteeringEdit: React.FC = () => {
  const dirPath = '/diretorio/Steering'

  const navigate = useNavigate();
  const { addToast } = useToast();
  const { signedUser } = useAuth();
  const { setIsLoading } = useGlobal();
  const { clearSteeringCreate, steeringCurrent } = useProjects();

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    clearSteeringCreate();
    navigate(dirPath);
  }, [clearSteeringCreate, navigate]);

  const handleCreateButton = useCallback(async () => {
    if (!steeringCurrent) return;
    const formatedBody = formatProject({} as any);
    const canSubmitForm = await beforeSubmitForm(formatedBody, ProjectSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowCreateModal(true);
  }, [steeringCurrent]);

  const handleEditSteering = useCallback(async () => {
    setShowCreateModal(false);
    if (!signedUser || !steeringCurrent) return;
    setIsLoading(true);
    // const formatedBody = formatProject(steeringCurrent);
    // const res = await new CreatePlaceService(signedUser.token).execute(formatedBody);
    const res = await true
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Steering',
        description: 'Steering atualizada com sucesso.',
      });
      clearSteeringCreate();
      return navigate(dirPath);
    }

    addToast({
      status: 'danger',
      title: 'Steering',
      description: 'Não foi possível atualizar a Steering.',
    });
  }, [addToast, clearSteeringCreate, navigate, setIsLoading, steeringCurrent, signedUser]);

  return (
    <Fragment>
      <SteeringForm
        headerTitle="Editar Steering"
        headerPathToReturn={dirPath}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleCreateButton}
        pathParticipatingAlicerce="/diretorio/Steering/criar/selecionar/contrato"
        pathParticipatingPartner="/diretorio/Steering/criar/selecionar/turmas"
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showCreateModal}
        closeCallback={() => setShowCreateModal(false)}
        title="Deseja Criar esta Steering?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleEditSteering}
      />
    </Fragment>
  );
};

export default SteeringEdit;
