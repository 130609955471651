import React from 'react';

import { Grid, H6, Textarea } from 'react-alicerce-components';

import { useProjects } from '@modules/projects/context/ProjectsContext';
import { useSteeringForm } from '../useSteeringForm';

interface SteeringAtaFragmentProps {
    disabled?: boolean
}

export const SteeringAtaFragment: React.FC<SteeringAtaFragmentProps> = ({
    disabled,

}) => {

    const { steeringCurrent } = useProjects();
    const { handleChangeInput } = useSteeringForm();

    return (
        <Grid row mt4>
            <H6>ATA DA STEERING:</H6>
            <Textarea
                disabled={disabled}
                name="ata"
                label="Ata:"
                placeholder="Adicione a ata da Steering"
                onChange={handleChangeInput}
                value={steeringCurrent?.ata}
                rows={25}
            />
        </Grid>
    );
}