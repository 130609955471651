export const STATUS_IMPLANTATION = { name: 'Implementação', value: 'implantation' };
export const STATUS_CLOSED = { name: 'Fechado', value: 'closed' };
export const STATUS_EXECUTION = { name: 'Execução', value: 'execution' };

export type IGroupValidStatus = 'implantation' | 'closed' | 'execution';

export const PLACE_STATUS_CONSTRAINTS = [STATUS_EXECUTION, STATUS_CLOSED, STATUS_IMPLANTATION];

export const defaultPlaceStatus = (placeStatus?: string | null) => {
  return [
    { name: STATUS_IMPLANTATION.name, value: STATUS_IMPLANTATION.value, selected: placeStatus === STATUS_IMPLANTATION.value },
    { name: STATUS_CLOSED.name, value: STATUS_CLOSED.value, selected: placeStatus === STATUS_CLOSED.value },
    { name: STATUS_EXECUTION.name, value: STATUS_EXECUTION.value, selected: placeStatus === STATUS_EXECUTION.value },
  ];
};

export const STATUS_PLACE_VALUES = PLACE_STATUS_CONSTRAINTS.map((c) => c.value);
