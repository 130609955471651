import React, { createContext, useCallback, useContext, useState } from 'react';
import IClass from '../interfaces/IClass';

export type IClassContextData = {
  classCurrent: IClass;
  setClassCurrent: (value: any) => void;
  clearClassContextData: () => void;
  pathToReturn: string;
  setPathToReturn: (value: string) => void;
};

const initialState = { name: '', age_groups: [], calendar_data: [], customization: undefined };

const ClassContext = createContext<IClassContextData>({} as IClassContextData);

const ClassProvider: React.FC = ({ children }) => {
  const [classCurrent, setClassCurrent] = useState<IClass>(initialState);
  const [pathToReturn, setPathToReturn] = useState<string>('')

  const clearClassContextData = useCallback(() => {
    setClassCurrent(initialState);
  }, []);

  return (
    <ClassContext.Provider
      value={{  classCurrent, setClassCurrent, clearClassContextData, pathToReturn, setPathToReturn }}
    >
      {children}
    </ClassContext.Provider>
  );
};

function useClass() {
  const context = useContext(ClassContext);

  if (!context) {
    throw new Error('useClass must be used within a ClassProvider');
  }

  return context;
}

export { ClassProvider, useClass };
