import React, { useCallback, useMemo } from 'react';
import UserList from '@modules/users/components/UsersList';
import { IUser } from '@modules/users/interfaces/IUser';
import { useClass } from '@modules/classes/context/ClassContext';

const ClassUsersRelation: React.FC = () => {
  const { setClassCurrent, classCurrent } = useClass();

  const setSelectedItens = useCallback(
    (teachers: IUser[]) => {
      setClassCurrent((oldState: any) => {
        return { ...oldState, teachers: teachers };
      });
    },
    [setClassCurrent]
  );

  const selectedItens = useMemo(() => {
    return classCurrent.teachers;
  }, [classCurrent.teachers]);

  return (
    <UserList
      title="Diretório de Usuários"
      btnText="Adicionar Usuário"
      // pathRedirect="/diretorio/turmas/criar"
      // pathToReturn=""
      selectedItens={selectedItens}
      setSelectedItens={setSelectedItens}
      canSelect={'many'}
      handleOnClick={setSelectedItens}
      pathRedirect={''}
    />
  );
};

export { ClassUsersRelation };
